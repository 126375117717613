.GlpSwap-tooltip {
  padding: 15px;
  background-color: #1e0839;
}

.GlpSwap-content {
  display: flex;
  margin-bottom: 22px;
  /* justify-content: center;
  align-items: center; */
  /* align-items: center; */
}

/* .Tab-title {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 3.41rem;
  font-weight: 500;
}

.Tab-description {
  letter-spacing: 0px;
  color: #a9a9b0;
  margin-top: 0.8rem;
}

.Tab-description span {
  color: #f89191;
} */

.GlpSwap-stats-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 753px;
  padding: 10px 70px 0 20px;
  border-bottom: 1px solid #7120c8;
  border-right: 1px solid #7120c8;
  border-top: 1px solid #7120c8;
}

.GlpSwap-stats-card-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}

.GlpSwap-stats-card-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.GlpSwap-stats-card-title-image-wrapper {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GlpSwap-stats-card-title-image {
  width: 40px;
  height: 40px;
}

.GlpSwap-stats-card-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.GlpSwap-stats-card-price-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.GlpSwap-stats-card-price {
  width: 122px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6b7c86;
  margin-right: 8px;
}

.GlpSwap-stats-card-price-value {
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.GlpSwap-card-wallet-row {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  margin-bottom: 24px;
}

.GlpSwap-wallet-value-wrapper {
  display: flex;
  flex-direction: column;
}

.GlpSwap-wallet-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fff;
}

.GlpSwap-wallet-value-usd {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #6b7c86;
}

.GlpSwap-card-stake-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-left: 50px;
}

.GlpSwap-card-stake-row-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fefdff;
}

.GlpSwap-card-stake-row-value-wrapper {
  display: flex;
  flex-direction: column;
}

.GlpSwap-card-stake-row-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fff;
}

.GlpSwap-card-stake-row-value-usd {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #6b7c86;
}

.GlpSwap-card-totalSupply-row {
  padding-left: 7px;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
  height: 69px;
  border-top: 1px solid #7120c8;
}

.GlpSwap-card-totalSupply-row-title-wrapper {
  display: flex;
  flex-direction: column;
}

.GlpSwap-card-totalSupply-row-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.GlpSwap-card-totalSupply-row-subtitle {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #fbd365;
}

.GlpSwap-card-totalSupply-row-value-wrapper {
  display: flex;
  flex-direction: column;
}

.GlpSwap-card-totalSupply-row-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fff;
}

.GlpSwap-card-totalSupply-row-value-usd {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #6b7c86;
}

.GlpSwap-box-wrapper {
  position: relative;
  /* border: 1px solid #1e2136; */
  /* border-radius: 0.4rem; */
  /* background: #16182e; */
  font-size: var(--font-base);

  box-sizing: border-box;

  background: rgba(113, 32, 200, 0.44);
  /* Main */

  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #7120c8;
}

.GlpSwap-swap-option-tabs {
  margin-bottom: 29px;
}

.GlpSwap-box-balance-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 23px;
}

.GlpSwap-box-balance-title {
}

.GlpSwap-box-balance-value-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.GlpSwap-box-balance-value {
  margin-right: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fbd365;
}

.GlpSwap-box-balance-value-usdt {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.GlpSwap-swap-box-wrapper {
  padding: 0 23px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  margin-bottom: 20px;
}

.GlpSwap-box-fees-wrapper {
  padding: 0 23px 0 20px;
  margin-bottom: 20px;
}

.GlpSwap-box-fees-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fefdff;
}

.GlpSwap-box-fees-value {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: #fbd365;
}

.GlpSwap-box-button-wrapper {
  display: flex;
  align-items: center;
  padding: 0 23px 30px 20px;
}

.GlpSwap-box-button-image-wrapper {
  margin-right: 10px;
}

.GlpSwap-box-button-image {
  width: 40px;
  height: 40px;
}

.GlpSwap-box-button {
  width: 100%;
  padding: 12px 32px;
  background: #7120c8;
  border: 1px solid #7120c8;
  border-radius: 33px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fefdff;
}

.GlpSwap-box-button:hover {
  background: #7120c8a9;
}

.GlpSwap-box-button:active {
  background: #7120c8a9;
}

.GlpSwap-title-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.GlpSwap-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #fbd365;
  display: flex;
}

.GlpSwap-title-part {
  margin-left: 10px;
  color: #fefdff;
}

.GlpSwap-Page-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #fefdff;
}

.GlpSwap-token-list {
  /* border: 1px solid #1e2136;
  border-radius: 4px; */
  /* background: inherit; */
  background: #120124;
  padding: 1.5rem;
  padding-top: 0;
  text-align: left;
}

.GlpSwap-token-table {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  width: calc(100% + 3.1rem);
  border-spacing: 0;
  white-space: nowrap;
  font-size: var(--font-base);
}

.GlpSwap-token-grid {
  display: none;
}

.GlpSwap-token-table td,
.GlpSwap-token-table th {
  padding: 1.1625rem;
}

.GlpSwap-token-table td:nth-child(2),
.GlpSwap-token-table th:nth-child(2) {
  width: 15rem;
}

.GlpSwap-token-table td:nth-child(3),
.GlpSwap-token-table th:nth-child(3) {
  width: 15rem;
}

.GlpSwap-token-table td:nth-child(4),
.GlpSwap-token-table th:nth-child(4) {
  width: 25rem;
}

.GlpSwap-token-table td:nth-child(5),
.GlpSwap-token-table th:nth-child(5) {
  width: 13rem;
}

.GlpSwap-token-table td {
  letter-spacing: 0.25px;
  color: #ffffffde;
}

.GlpSwap-token-table th:first-child,
.GlpSwap-token-table td:first-child {
  padding-left: 19px;
  width: 26.35rem;
}

.GlpSwap-token-table th:last-child,
.GlpSwap-token-table td:last-child {
  padding-right: 21px;
  text-align: right;
  width: 17rem;
}

.GlpSwap-token-table th {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.7);
  /* text-transform: uppercase; */
  border-bottom: 1px solid #581dad;
}

.GlpSwap-token-table .action-btn {
  width: 100%;
  justify-content: center;
}

.GlpSwap-card-info-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.GlpSwap-card-info-subtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: #ebe2dd;
}

.GlpSwap-card-info-label {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.GlpSwap-card-info-label-part {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  color: #6b7c86;
}

.GlpSwap-card-info-wallet-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.GlpSwap-card-info-button {
  width: 100%;
  padding: 8px 32px;
  border: 1px solid #7120c8;
  background-color: inherit;
  border-radius: 33px;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fefdff;
}

.GlpSwap-mobile-token-card {
  padding: 20px;
  border: 1px solid #7120c8;
  background: #120124;
}

.GlpSwap-card-divider {
  height: 1px;
  background: #7120c8;
  margin: 1.05rem -1.5rem;
}

.GlpSwap-mobile-token-card-button {
  width: 100%;
  padding: 8px 32px;
  border: 1px solid #7120c8;
  background-color: inherit;
  border-radius: 33px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #fefdff;
}

/* ------------------------- */

.GlpSwap-from-token .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.GlpSwap-from-token .Modal .Modal-content {
  width: 41.85rem;
}

.GlpSwap-from-token .Modal-content {
  position: absolute;
  width: 38.75rem;
  max-height: 100vh;
  overflow: hidden;
  border: none;
  top: 0;
  bottom: 0;
}
.GlpSwap-from-token .Modal-content .Modal-body {
  padding-right: 0.5rem;
  max-height: calc(100vh - 7.75rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
}

.GlpSwap-content .GlpSwap-stats-card,
.GlpSwap-content .GlpSwap-box {
  width: 100%;
}

.GlpSwap-content .GlpSwap-stats-card {
  /* max-width: 71.2rem;
  margin-right: 1.5rem; */
}

.GlpSwap-stats-card .App-card-row .label {
  color: #a9a9b0;
  opacity: 1;
}

.GlpSwap-content .GlpSwap-box {
  max-width: 47.95rem;
  border-radius: 0.4rem;
  /* padding: 1.5rem; */
  padding-top: 0;
}

.GlpSwap-box .Exchange-swap-option-tabs {
  margin: 1.5rem 0;
}

.App-card-title-mark-icon {
  position: relative;
  margin-right: 0.8rem;
  display: flex;
  width: 40px;
  height: 40px;
}

.App-card-title-mark-icon .selected-network-symbol {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #1e1f37;
  border-radius: 50%;
}

.App-card-title-mark {
  display: flex;
  align-items: center;
}

.App-card-title-mark .App-card-title-mark-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.GlpSwap-token-list-content {
  display: grid;
  padding: 1.5rem;
  padding-top: 0;
  text-align: left;
}

.GlpSwap-box .App-card-title {
  margin-bottom: 0.8rem;
}

.GlpSwap-box .Exchange-info-row {
  height: 1.5rem;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GlpSwap-box .Exchange-info-row .Exchange-info-label {
  margin-right: 0.8rem;
  color: #a9a9b0;
  opacity: 1;
}

.GlpSwap-cta {
  margin: 0 auto;
  text-align: center;
  padding-top: 0;
}

.GlpSwap-cta .Exchange-swap-button {
  margin: 0 auto;
  justify-content: center;
  min-height: 4.8rem;
  font-size: var(--font-sm);
}

.token-table {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  width: calc(100% + 3.1rem);
  border-spacing: 0;
  white-space: nowrap;
  font-size: var(--font-base);
}

.token-grid {
  display: none;
}

.token-table td,
.token-table th {
  padding: 1.1625rem;
}

.token-table td:nth-child(2),
.token-table th:nth-child(2) {
  width: 11rem;
}

.token-table td:nth-child(3),
.token-table th:nth-child(3) {
  width: 32rem;
}

.token-table td:nth-child(4),
.token-table th:nth-child(4) {
  width: 20rem;
}

.token-table td:nth-child(5),
.token-table th:nth-child(5) {
  width: 13rem;
}

.token-table td {
  letter-spacing: 0.25px;
  color: #ffffffde;
}

.token-table th:first-child,
.token-table td:first-child {
  padding-left: 19px;
  width: 26.35rem;
}

.token-table th:last-child,
.token-table td:last-child {
  padding-right: 21px;
  text-align: right;
  width: 17rem;
}

.token-table th {
  font-weight: normal;
  letter-spacing: 0.25px;
  color: #a9a9b0;
  text-transform: uppercase;
}

.token-table .action-btn {
  width: 100%;
  justify-content: center;
}
.cap-reached {
  margin-left: 0.8rem;
}

.AppOrder-ball-container {
  position: relative;
  z-index: 2;
}

.AppOrder-ball-icon {
  text-align: center;
  display: block;
  font-size: var(--font-lg);
  opacity: 0.7;
}

.AppOrder-ball {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 3.565rem;
  height: 3.565rem;
  left: 50%;
  margin-left: -1.7825rem;
  top: -2.2rem;
  border-radius: 3.1rem;
  user-select: none;
  background: linear-gradient(90deg, rgba(45, 66, 252, 1) 0%, rgba(46, 61, 205, 1) 100%);
  cursor: pointer;
  opacity: 0.85;
}
.AppOrder-ball:hover {
  opacity: 1;
}

@media (max-width: 900px) {
  .GlpSwap-content {
    flex-wrap: wrap;
  }

  .GlpSwap-content .GlpSwap-stats-card {
    margin: 0;
    margin-bottom: 3.1rem;
    max-width: 100%;
    border: 1px solid #7120c8;
  }

  .GlpSwap-content .GlpSwap-box {
    margin: 0;
    max-width: 100%;
    border: 1px solid #7120c8;
  }
}

@media (max-width: 1100px) {
  .GlpSwap-from-token .Modal {
    align-items: center;
    justify-content: center;
  }
  .GlpSwap-from-token .Modal .Modal-content {
    width: 41.85rem;
    position: absolute;
    max-height: 80vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }
  .GlpSwap-from-token .Modal .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(80vh - 8.525rem);
    padding-right: 5px;
    margin: 1.5rem calc(1rem - 0.5rem) 1.5rem 1.5rem;
  }

  .GlpSwap-token-list {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .GlpSwap-token-list-content {
    padding: 0;
  }

  .token-table {
    display: none;
  }

  .GlpSwap-token-table {
    display: none;
  }

  .token-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    margin-top: 0.8rem;
  }
}
@media (max-width: 510px) {
  .token-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    margin-top: 0.8rem;
  }
}
