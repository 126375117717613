.connect-wallet-btn {
  background: transparent;
  border: 1px solid #7120c8;
  border-radius: 33px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #fefdff;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  padding: 7px 8px 6px 10px;
  transition: 0.2s ease-in-out;

  .btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .btn-label {
    font-weight: 400;
    font-size: var(--font-sm);
    margin-left: 0.8rem;
    letter-spacing: 0;
  }
  &:hover {
    background: #7120c8;
    color: #fff;
    transition: 0.2s ease-in-out;
  }
  &:active {
    background: var(--dark-blue-active);
  }
}

@media (max-width: 500px) {
  .connect-wallet-btn {
    .btn-label {
      font-size: 8.5px;
    }

    .btn-icon {
      width: 20px;
      height: 20px;
    }
  }
}

@media (max-width: 450px) {
  .connect-wallet {
    padding: 0.5rem 0.8rem;
  }
}

@media (max-width: 350px) {
  .connect-wallet-btn {
    height: 3.6rem;
    .btn-icon {
      display: none;
    }
    .btn-label {
      margin-left: 0px;
    }
  }
}
