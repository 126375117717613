.buy-glp-content {
    padding-top: 4.65rem;
}

.BuyGlp-page-layout {
    min-height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    padding-bottom: calc(4.65rem + 5rem);
    padding-top: 1.65rem;
  }

.BuyGlp-section-title-block {
  margin-bottom: 34px;
}

.BuyGlp-section-title-icon {
  
}

.BuyGlp-section-title-content {

}

.BuyGlp-Page-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -2px;
  color: #fbd365;
  display: flex;
}

.BuyGlp-Page-title-part {
  margin-left: 5px;
  color: #fefdff;
}

.BuyGlp-Page-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #fefdff;
}
