.position-share-modal .Modal-content {
  min-width: 36rem;
  width: 40rem;
}
.position-share-modal .actions {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.position-share-modal button,
.position-share-modal a {
  background: none;
  color: white;
  font-size: 1.45rem;
  border: none;
  opacity: 0.9;
}

.position-share-modal button:hover,
.position-share-modal a:hover {
  opacity: 1;
}

.position-share-modal button:active,
.position-share-modal a:active {
  opacity: 0.8;
}

.position-share-modal .icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.position-share {
  position: relative;
  z-index: 10;
  aspect-ratio: 1200 / 675;
  background-color: #0d0417;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.position-share .logo {
  width: 7rem;
}
.position-share .info {
  list-style: none;
  color: white;
  font-size: 1.25rem;
  display: flex;
  padding-left: 0;
  margin: 0;
}
.position-share .info li {
  margin-right: 1rem;
  padding-bottom: 0;
}
.position-share .info > .side {
  color: #fbd365;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.PositionShare-info-row {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #fefdff;
}

.position-share .pnl {
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -2px;
  color: #fefdff;
  margin: 2px 0;
}

.position-share .prices {
  max-width: 20rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.position-share .prices p {
  margin: 0;
  /* font-size: 1.1rem; */

  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #6b7c86;
}

.position-share .prices p.price {
  /* font-size: var(--font-base);
  font-weight: bold; */
  margin-top: 0.25rem;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fefdff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.position-share .prices div {
  display: flex;
  flex-direction: column;
}

.position-share .referral-code-wrapper {
  display: flex;
  position: relative;
  align-items: center;
}
.position-share .referral-code-qrCode {
  position: relative;
  z-index: 20;
  width: 42px;
  height: 42px;
}

.position-share .referral-code {
  position: relative;
  left: -5px;
  display: flex;
  background: linear-gradient(90deg, #7120C8 0%, rgba(113, 32, 200, 0) 69.27%);
  max-width: 18rem;
  width: 20rem;
  border-radius: 5px;
  align-items: center;
  padding: 0.5rem;
  padding-left: 28px;
}
.position-share .referral-code div {
  display: flex;
  flex-direction: column;
}
.position-share .referral-code .label {
  font-size: 0.9rem;
  line-height: 1;
}
.position-share .referral-code .referral-code-info {
  margin-left: 1rem;
}

.position-share .referral-code img {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
}
.position-share .referral-code p {
  margin: 0;
}
.position-share .code {
  /* font-weight: 500;
  line-height: 1; */
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #fbd365;
}

.position-share .position-share-bgImage-wrapper {
  position: absolute;
  right: -5px;
  top: 20px;
  z-index: 2;
}

.position-share .position-share-bgImage {
  width: 176px;
  height: 176px;
}

.position-share .position-share-bgImage-circle {
  position: absolute;
  right: 5px;
  top: 50px;
  width: 125px;
  height: 125px;
  background-color: #7120c8;
  border-radius: 50%;
  z-index: 1;
}

.tweet-link-container.disabled {
  cursor: not-allowed;
}

.position-share-modal .App-button-option {
  justify-content: center;
}

a.App-button-option.tweet-link {
  text-decoration: none;
  width: 100%;
}
a.App-button-option.tweet-link.disabled {
  pointer-events: none;
}

.image-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
}
.image-overlay-wrapper {
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 0;
  background: #00000055;
  color: #fff;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  float: right;
  padding: 0 0.25rem;
  border-top-left-radius: 5px;
}
.image-overlay-wrapper .loading-text {
  font-size: 1.1rem;
  margin: 0 0 0 0.5rem;
  color: #fbd365;
}
.image-overlay {
  display: flex;
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
}

.image-overlay .spinning-loader {
  margin-left: 0.5rem;
}

@media (max-width: 400px) {
  .position-share-modal .Modal-content {
    min-width: 28rem;
    width: 28rem;
  }
  .position-share-modal .Modal-body {
    margin: 1rem;
  }
  .position-share-modal .Modal-title-bar {
    margin: 1rem;
  }
  .position-share {
    padding: 1rem;
  }
  .position-share .logo {
    width: 5.5rem;
  }
  .position-share .info {
    font-size: 1rem;
  }
  .position-share .pnl {
    font-size: 2rem;

    line-height: 1;
  }
  .position-share .prices {
    max-width: 14rem;
  }
  .position-share .prices p {
    font-size: 0.9rem;
  }
  .position-share .prices p.price {
    font-size: 1rem;
  }
  .position-share .referral-code {
    width: 12rem;
    padding-left: 18px;
  }
  .position-share .referral-code .label {
    font-size: 0.8rem;
  }
  .position-share .referral-code p {
    font-size: 1rem;
  }
  .position-share-modal .actions {
    margin-top: 1rem;
  }
  .position-share-modal .actions .App-button-option {
    font-size: 1rem;
    line-height: 1.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 3rem;
  }
  .image-overlay-wrapper .loading-text {
    font-size: 1rem;
  }

  .PositionShare-info-row {
    font-size: 8px;
    line-height: 10px;
  }

  .position-share .referral-code-qrCode {
    width: 32px;
    height: 32px;
  }

  .position-share .position-share-bgImage-wrapper {
    right: -5px;
    top: 10px;
  }
  
  .position-share .position-share-bgImage {
    width: 136px;
    height: 136px;
  }
  
  .position-share .position-share-bgImage-circle {
    right: 2px;
    top: 35px;
    width: 100px;
    height: 100px;
  }
}
