.DashboardV2-projects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}

.Ecosystem-page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  padding-bottom: calc(2.65rem + 10rem);
  padding-top: 4.65rem;
}

.Ecosystem-z-index-10 {
  z-index: 10;
}

.Ecosystem-position-relative {
  position: relative;
}

.Ecosystem-section-title-content {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  /* justify-content: flex-end; */
}

.Ecosystem-yellow-rectangle-image {
  margin-right: 9px;
  width: 5px;
  height: 30px;
}

.Ecosystem-title-image {
  margin-right: 4px;
  width: 24px;
  height: 24px;
}



.Ecosystem-page-title {
  font-size: 36px;
  font-weight: 600;
  /* margin-bottom: 4.1rem; */
  letter-spacing: -2px;
}

.Ecosystem-section-title-block {
  margin-top: 4.1rem;
  margin-bottom: 2.2rem;
  padding-bottom: 4px;
  display: flex;
  width: 100%;
  /* border-bottom: 1px solid #581dad; */
}

.Ecosystem-section-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #fefdff;
}

.Ecosystem-section-title-description {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #6b7c86;
}

.Ecosystem-projects {
  display: flex;
  flex-wrap: wrap;
  /* row-gap: 18px; */
  /* column-gap: 20px; */
}

.Ecosystem-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  height: 131px;
  /* min-height: 110px; */
  position: relative;
  padding: 10px 24px;
  background: #0d0417;
  border: 1px solid #7120c8;
  /* border-radius: 10px; */
}

.Ecosystem-card-height-long {
  height: 131px;
}

.Ecosystem-card-height-short {
  height: 60px;
}

.Ecosystem-card-width-long {
  width: 590px;
}

.Ecosystem-card-title-block {
  
}

.Ecosystem-card-title-block-with-image {
  display: flex;
}

.Ecosystem-card-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #fefdff;
}

.Ecosystem-card-subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #6b7c86;
}

.Ecosystem-card-title-image-wrapper {
  margin-right: 10px;
  width: 40px;
  height: 40px;
}

.Ecosystem-card-title-image {
  width: 40px;
  height: 40px;
}

.Ecosystem-card-link {
  color: #fbd365;
  text-decoration: none;
}

.Ecosystem-card-link-image-arrow {
  width: 20px;
  height: 20px;
}

.Ecosystem-card-link-community {
  color: #6b7c86;
  text-decoration: none;
}

.Ecosystem-card-announcement {
  align-self: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #6b7c86;
}

.Ecosystem-card-announcement-image {
  width: 24px;
  height: 24px;
}

.Ecosystem-card-announcement-link {
  height: 24px;
}

.Ecosystem-bg-top-image {
  z-index: 0;
  position: absolute;
  width: 799px;
  height: 1626px;
  background: #7120c8;
  opacity: 0.3;
  filter: blur(150px);
  right: -250px;
  top: 50px;
}

.Ecosystem-bg-bottom-image {
  z-index: 0;
  position: absolute;
  width: 799px;
  height: 1626px;
  background: #cc8cfB;
  opacity: 0.3;
  filter: blur(150px);
  left: -250px;
  bottom: 125px;
}

@media (max-width: 1270px) {
  .Ecosystem-card {
    max-width: 450px;
  }
}

@media (max-width: 970px) {
  .Ecosystem-section-title-block {
    justify-content: center;
  }

  .Ecosystem-projects {
    justify-content: center;
  }
}

@media (max-width: 900px) {
  .DashboardV2-projects {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .Ecosystem-section-title-block {
    justify-content: start;
  }
}