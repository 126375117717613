.chart-token-selector {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.chart-token-selector-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-token-selector-tokens-wrapper {
  display: flex;
  align-items: center;
}

.chart-token-selector--current {
  font-size: 1.68rem;
  font-weight: 600;
  margin-right: 0.8rem;
  /* font-size: 2.1rem;
  font-weight: 700;
  margin-right: 1rem; */
  color: white;
}

.chart-token-menu-items.menu-items {
  width: 248px;
  top: 6rem;
  right: unset;
  left: 9px;
  padding: 12px 14px 12px 0;
  border-radius: 10px;
}

.chart-token-menu-items .menu-item {
  font-size: var(--font-sm);
  height: 3.4rem;
  color: #a0a3c4;
}

.chertTokenSelectorValues__images {
  display: flex;
  margin-right: 2px;
  width: 48px;
  height: 24px;
}

.chertTokenSelectorValues__image {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
}

.chartTokenSelectorValues__image_move {
  position: relative;
  left: 10px;
}

@media (max-width: 1100px) {
  .chart-token-selector--current {
    font-size: 1.8rem;
  }
}

@media (max-width: 700px) {
  .chart-token-selector--current {
    font-size: 1.4rem;
    text-align: center;
  }
}