.Referrals {
  padding-top: 4.65rem;
}

.section-center {
  margin: 0 auto;
  text-align: center;
}
.mt-large {
  margin-top: 3.1rem;
}
.mt-medium {
  margin-top: 1.5rem;
}
.referral-tab-container {
  margin: 1.5rem auto 3.1rem auto;
}
.referral-card {
  position: relative;
  padding: 16px;
  /* border-radius: 1.3rem; */
  max-width: 59.2rem;
  background: #120124;
  /* background: #16182e; */
  /* box-shadow: 0px 0.5rem 1.3rem #00000085; */
  border: 1px solid #581dad;
  /* border-radius: 20px; */
}
.referral-card .title {
  margin: 0;
  padding-bottom: 4px;
  /* font-weight: 500;
  font-size: var(--font-lg);
  line-height: 3.1rem;
  color: #ffffff;
  padding: 0.775rem 0;
  margin: 0; */
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fefdff;
  border-bottom: 1px solid #7120c8;
}

.referral-card .sub-title {
  /* height: 39px; */
  /* font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: 0px;
  color: #fff;
  margin-top: 1.1625rem; */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fff;
}
.referral-card .card-action {
  margin-top: 2.6rem;
  /* margin-top: 3.1rem; */
}

.referral-card .card-action input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: var(--font-base);
  width: 100%;
  box-sizing: border-box;
  margin-right: 1.5rem;
}
.referral-card-margin-right {
  margin-right: 16px;
}
.referral-body-container .list {
  margin-top: 1.1rem;
}
.reward-history {
  margin-top: 3.1rem;
}
.active-referral-code .edit {
  display: flex;
  align-items: center;
  justify-content: center;
}
.active-referral-code .edit span {
  margin-right: 1.5rem;
}
.active-referral-code .edit svg {
  cursor: pointer;
}
.active-referral-code .edit svg:hover {
  opacity: 0.75;
}
.active-referral-code .tier {
  margin-top: 0.775rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active-referral-code .tier span {
  font-size: var(--font-sm);
  color: #a9a9b0;
  margin-right: 0.775rem;
}
.active-referral-code .tier a {
  text-decoration: none;
  font-size: 0;
}
.mb-sm {
  margin-bottom: 1.5rem;
}
.edit-referral-modal {
  width: 31rem;
}
.referral-code-edit span {
  margin-right: 0.775rem;
}
.referral-code-edit svg {
  opacity: 0.75;
  cursor: pointer;
}

.referral-code-edit svg:hover {
  opacity: 1;
}
.create-referrar-code {
  text-align: center;
  max-width: 50rem;
  margin: 3.1rem auto;
}
.transparent-btn {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  background: transparent;
  padding: 0.75rem 1.25rem;
  color: #fbd365;
  font-size: var(--font-sm);
  border: 1px solid #7120c8;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
}

.transparent-btn:hover {
  background: var(--dark-blue-hover);
}

.transparent-btn:active {
  background: var(--dark-blue-active);
}

.referral-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.referral-table-header .title {
  margin: 0;
}
.referral-table-header .sub-title {
  color: #6b7c86;
  font-size: var(--font-sm);
  margin-left: 0.775rem;
}

.referral-table-header .ml-small {
  margin-left: 0.775rem;
}
.create-referrar-code form {
  display: flex;
  align-items: inherit;
  justify-content: center;
  margin: 3.1rem 0;
}
.create-referrar-code form input {
  border: 1px solid #1e2136;
  border-radius: 0.4rem;
  padding: 1.1625rem;
  font-size: var(--font-base);
  width: 40rem;
}
.create-referrar-code form button {
  margin-left: 1.5rem;
}

.referral-stats {
  margin: 0 auto;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
}

.text-center {
  text-align: center;
}

.info-card {
  /* background: var(--dark-blue-bg); */
  border: 1px solid #581dad;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-card .label {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #fefdff;
  margin-bottom: 0.7rem;
  text-align: center;
}
.info-card .data {
  font-size: 18px;
  /* font-size: var(--font-lg); */
  font-weight: normal;
  text-align: center;
  padding: 0;
  margin: 1.5rem 0;
}
.info-card .info-card-question-icon {
  vertical-align: middle;
  font-size: var(--font-sm);
  opacity: 0.8;
}

.table-referral-code {
  align-items: center;
  display: flex;
}

.table-referral-code .referral-text {
  margin-right: 0.775rem;
  font-family: monospace;
}

.table-referral-code .referral-code-icon {
  margin-right: 1rem;
  cursor: pointer;
  display: inline-flex;
}

.table-referral-code .referral-code-icon:hover {
  opacity: 0.75;
}

.table-referral-code .info {
  margin-left: 0.775rem;
}

.referral-table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  font-size: var(--font-base);
}
.referral-table th {
  font-weight: normal;
  letter-spacing: 0.25px;
  color: #a9a9b0;
  text-transform: uppercase;
}
.referral-table th,
.referral-table td {
  padding: 0.625em;
  text-align: left;
}

.table-referral-code p {
  margin: 0;
  margin-left: 0.775rem;
  padding: 0;
}
.error {
  margin: 0;
  padding: 0.775rem 0 1.5rem;
  color: #fa3c58;
  font-size: var(--font-sm);
}

.table-wrapper {
  display: block;
  width: 100%;
  font-size: var(--font-base);
}

.empty-message {
  margin: 1.65rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid #7120c8;
  border-radius: 0.4rem;
  /* background: #16182e; */
  font-size: var(--font-base);
}
.confirm-checkbox .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 1.5rem;
}

.Referrals-title-block {
  display: flex;
  margin-bottom: 2.325rem;
  max-width: 58.4rem;
  width: 100%;
}

.Referrals-page-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -2px;
  color: #fff;
}

.Referrals-block-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.Referrals-block-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 54px;
}

.Referrals-about {
  max-width: 510px;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #fefdff;
}

.Referrals-about-account-block-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.Referrals-about-account-block-wrapper-image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.Referrals-about-account {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fefdff;
}

.Referrals-about-account-button-wrapper {
  width: 100%;
}

.Referrals-about-account-button {
  width: 100%;
  max-width: 460px;
}

.referrals-tabs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.referral-card-wrapper {
  display: flex;
  flex-direction: column;
}

.referral-card-custom-button {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  padding: 8px 32px;
  border-radius: 30px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fff;
  text-decoration: none;
  background: #7120c8;
}

.referral-card-custom-button:hover:enabled {
  background: #7120c8ce;
}

.referral-card-custom-button:active:enabled {
  background: #7120c8cb;
}

.referralLogo {
  margin-bottom: 18px;
  display: flex;
}

.referralLogo__image {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.referralLogo__title {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
}

.referral-card-text-input {
  padding: 11px 32px;
  border: 1px solid #6b7c86;
  /* border-radius: 30px; */
  font-size: var(--font-base);
  width: 100%;
  box-sizing: border-box;
}

.Referrals-yellow-rectangle-image {
  margin-right: 4px;
  width: 5px;
  height: 30px;
}

.Referrals-alert-wrapper {
  display: flex;
  align-items: center;
}

.ReferralsAlertTriangleImage {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.Referrals-alert {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #fbd365;
}

.Referrals-page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  padding-bottom: calc(4.65rem + 5rem);
  padding-top: 2.65rem;
}

@media (max-width: 1100px) {
  .Referrals-block-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  .Referrals-title-block {
    display: flex;
    margin-bottom: 2.325rem;
    max-width: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .referral-table {
    width: auto;
    margin-bottom: 1.5rem;
  }
  .table-wrapper {
    overflow-x: auto;
  }
  .table-head {
    white-space: nowrap;
  }
}

@media (max-width: 650px) {
  .referral-stats {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .referral-table .table-head {
    font-size: 12px;
  }

  .referral-table th,
  .referral-table td {
    font-size: 12px;
  }
}

@media (max-width: 510px) {
  .referral-table .table-head {
    font-size: 10px;
  }

  .referral-table th,
  .referral-table td {
    font-size: 10px;
  }
}

@media (max-width: 500px) {
  .referral-table-header .sub-title {
    display: block;
    margin-left: 0;
    margin-top: 2px;
  }

  .Referrals-about-account {
    font-size: 10.5px;
    line-height: 14px;
  }

  .Referrals-about-account-block-wrapper-image {
    margin-right: 6px;
  }
}

@media (max-width: 450px) {
  .referral-table .table-head {
    font-size: 8px;
  }

  .referral-table th,
  .referral-table td {
    font-size: 9px;
  }
}
