.StakeV2 .Page-title-section {
  position: relative;
  z-index: 2;
}

.StakeV2-content {
  margin-bottom: 27px;
}

.VesterDepositModal-info-rows {
  margin-bottom: 0.8rem;
}

.CompoundModal-menu {
  margin-bottom: 0.8rem;
}

.CompoundModal-menu .Checkbox {
  margin-bottom: 0.465rem;
}

.StakeV2-address-input {
  padding: 1.5rem 3.41rem;
  padding-bottom: 0;
}

.StakeV2-buy-gmx-modal .Modal-content {
  max-width: 46.5rem;
}

.StakeV2-address-input input {
  box-sizing: border-box;
  width: 100%;
  font-size: 1.7rem;
}

.StakeV2-boost-bar {
  overflow: hidden;
  vertical-align: middle;
  margin-left: 0.31rem;
  border-radius: 2px;
  width: 1.5rem;
  height: 0.8rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: inline-block;
  position: relative;
}

.StakeV2-boost-icon {
  font-size: 1.085rem;
  z-index: 2;
}

.StakeV2-boost-bar-fill {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
}

/* .StakeV2-card-title {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #ebe2dd;
} */

/* .StakeV2-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
} */

/* .StakeV2-card {
  position: relative;
  border: 1px solid #581dad;
  border-radius: 20px;
  font-size: var(--font-base);
  padding: 1.5rem 1.5rem 1.86rem;
} */

/*-----------------------------------------*/

.StakeV2-Page-title {
  font-size: var(--font-xl);
  font-weight: 500;
  margin-bottom: 0.8rem;
  font-weight: bold;
}

.StakeV2-subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #fefdff;
}

.StakeV2-cards {
  display: flex;
  margin-bottom: 22px;
}

.StakeV2-Holdings-wrapper {
  display: flex;
  flex-direction: column;
}

.StakeV2-ManageAssets-wrapper {
  width: 100%;
}

.StakeV2-block-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}

.StakeV2-yellow-rectangle-image {
  margin-right: 8px;
  width: 5px;
  height: 30px;
}

.StakeV2-title-image {
  margin-right: 4px;
  width: 24px;
  height: 24px;
}

.StakeV2-block-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.StakeV2-Holdings-block {
  width: 511px;
  background: #0d0417;
  border-width: 1px 1px 1px 1px;
  /* border-radius: 20px 0px 0px 20px; */
  border-style: solid;
  border-color: #581dad;
}

.StakeV2-ManageAssets-block {
  width: 100%;
  max-width: 707px;
  background: #0d0417;
  /* border-width: 1px 0px 0px 0px; */
  border-width: 1px 1px 1px 0px;
  /* border-radius: 0px 20px 20px 0px; */
  border-style: solid;
  border-color: #581dad;
}

.StakeV2-ManageAssets-title-wrapper {
  margin-left: 25px;
}

.StakeV2-card {
  height: 208px;
  position: relative;
  border-bottom: 1px solid #581dad;
  display: flex;
  flex-direction: column;
}

.StakeV2-card-Holdings {
  padding: 10px 20px 20px 20px;
}

.StakeV2-Holdings-card-head-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.StakeV2-Holdings-card-title-wrapper {
  display: flex;
  align-items: center;
}

.StakeV2-Holdings-card-title-image {
  margin-right: 8px;
  width: 40px;
  height: 40px;
}

.StakeV2-Holdings-card-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.StakeV2-Holdings-card-price-image {
  width: 12px;
  height: 14px;
}

.StakeV2-Holdings-card-price-value {
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #fff;
}

.StakeV2-Holdings-card-APR-value {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #fff;
}

.StakeV2-Holdings-card-row {
  display: flex;
  justify-content: space-between;
}

.StakeV2-Holdings-wallet-esGMX-wrapper {
  display: flex;
  flex-direction: column;
}

.StakeV2-Holdings-wallet-esGMX {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fff;
}

.StakeV2-Holdings-wallet-esGMX-usd {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #6b7c86;
  align-self: self-end;
}

.StakeV2-margin-right-8px {
  margin-right: 8px;
}

.StakeV2-margin-right-20px {
  margin-right: 20px;
}

.StakeV2-margin-right-19px {
  margin-right: 19px;
}

.StakeV2-margin-right-15px {
  margin-right: 15px;
}

.StakeV2-Grey-16-Text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6b7c86;
}

.StakeV2-wallet-block-wrapper {
  display: flex;
  align-items: center;
}

.StakeV2-wallet-image {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.StakeV2-wallet-title {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #fefdff;
}

.StakeV2-Holdings-Multiplier-Points {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6b7c86;
  display: flex;
}

.StakeV2-Holdings-Multiplier-Points-value {
  margin-left: 4px;
  color: #fefdff;
}

.StakeV2-Holdings-card-tooltip-apr {
  background-color: #1e0839;
  width: 650px;
  height: 150px;
  padding: 20px;
}

.StakeV2-Holdings-card-tooltip {
  background-color: #1e0839;
  /* width: 650px;
  height: 150px; */
  padding: 20px;
  border-radius: 5px;
}

.StakeV2-link {
  color: #fbd365;
}

.StakeV2-card-row-title {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6b7c86;
}

.StakeV2-card-divider {
  height: 1px;
  background: #23263b;
  margin: 1.05rem -1.5rem;
}

.StakeV2-card-section {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 1px solid #1e0839;
}

.StakeV2-card-button-section {
  min-height: 50px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 10px;
}

.StakeV2-button-solid {
  padding: 8px 32px;
  background: #7120c8;
  border: 1px solid #7120C8;
  border-radius: 33px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #fefdff;
  text-decoration: none;
}

.StakeV2-button-solid:hover {
  opacity: 0.8;
}

.StakeV2-button-outline {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 32px;
  border: 1px solid #7120C8;
  border-radius: 33px;
  background: inherit;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #fefdff;
  text-decoration: none;
}

.StakeV2-card-ManageAssets {
  padding: 15px 21px 0 27px;
  gap: 12px
}

.StakeV2-card-ManageAssets-header {
  display: flex;
  width: 100%;
}

.StakeV2-card-ManageAssets-header-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 7px;
}

.StakeV2-card-ManageAssets-header-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 7px;
}

.StakeV2-card-ManageAssets-header-row {
  width: 100%;
  max-width: 305px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.StakeV2-card-ManageAssets-header-row-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6b7C86;
}

.StakeV2-card-ManageAssets-header-row-value {
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.StakeV2-card-ManageAssets-main {
  display: flex;
  justify-content: space-between;
}

.StakeV2-card-ManageAssets-header-row-value-total {
  padding-right: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.StakeV2-card-ManageAssets-hooter {
  display: flex;
  align-items: center;
  gap: 10px;
}


.StakeV2-TotalRewards-wrapper {
  display: flex;
  flex-direction: column;
}

.StakeV2-TotalRewards-block {
  padding: 20px 23px;
  width: 100%;
  height: 180px;
  background: #0d0417;
  border-width: 1px 1px;
  /* border-radius: 20px 20px; */
  border-style: solid;
  border-color: #581dad;
  display: flex;
  justify-content: space-between;
}

.StakeV2-TotalRewards-block-total-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  /* margin-right: 35px; */
}

.StakeV2-TotalRewards-block-total-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6b7c86;
}

.StakeV2-TotalRewards-block-total-value {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fff;
}

.StakeV2-TotalRewards-block-center {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-right: 59px;
  /* padding-bottom: 10px; */
}

.StakeV2-TotalRewards-block-titles-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 237px;
  gap: 21px;
}

.StakeV2-TotalRewards-block-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6b7c86;
  margin-bottom: 4px;
}

.StakeV2-TotalRewards-block-values-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 237px;
  align-items: end;
  /* margin-bottom: 20px; */
}

.StakeV2-TotalRewards-block-value-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: end;
}

.StakeV2-TotalRewards-block-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fff;

}

.StakeV2-TotalRewards-block-value-usd {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #6b7c86;
  margin-bottom: 4px;
}

.StakeV2-TotalRewards-block-4-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 330px;
  gap: 5px
}

.StakeV2-TotalRewards-block-4-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.StakeV2-TotalRewards-block-4-row-button-wrapper {
  display: flex;
  align-items: center;
}

.StakeV2-TotalRewards-block-4-row-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6b7c86;
}

.StakeV2-TotalRewards-block-4-row-value {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: #fff;
}

.StakeV2-Tab-title-section {
  margin-bottom: 15px;
}

.StakeV2-Vest-cards {
  display: flex;
  width: 100%;
  /* height: 200px; */
  /* margin-bottom: 20px; */
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: #0d0417;
}

/* .StakeV2-Vest-left-card {
  padding: 5px;
  width: 100%;
  max-width: 608px;
  height: 100%;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #581dad;
  display: flex;
  flex-direction: column;
}

.StakeV2-Vest-right-card {
  padding: 5px 5px 0 5px;
  width: 100%;
  max-width: 608px;
  height: 100%;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #581dad;
  display: flex;
  flex-direction: column;
} */

.StakeV2-Vest-card {
  padding: 20px;
  width: 100%;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #581dad;
  display: flex;
  flex-direction: column;
}

.StakeV2-Vest-card-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.StakeV2-Vest-buttons-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;
}

.StakeV2-Vest-card-title-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 265px;
}

.StakeV2-Vest-card-title-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.StakeV2-Vest-card-title-image-wrapper {
  /* margin-right: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.StakeV2-Vest-card-title-image {
  width: 40px;
  height: 40px;
}

.StakeV2-Vest-card-title-image-vmxftm {
  width: 55px;
  height: 40px;
}

.StakeV2-Vest-card-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.StakeV2-wallet-button {
  width: 100%;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #7120c8;
  border-radius: 33px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #fefdff;
  text-decoration: none;
  background: inherit;
}

.StakeV2-wallet-button:hover:enabled {
  background: #7120c8ce;
}

.StakeV2-wallet-button:active:enabled {
  background: #7120c8cb;
}

.StakeV2-Vest-card-value-block {
  /* padding-top: 14px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  gap: 5px;
}

.StakeV2-Vest-card-value-rows-wrapper {
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.StakeV2-Vest-card-value-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
}

.StakeV2-Vest-card-value-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6b7c86;
}

.StakeV2-Vest-card-value-value {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: #fff;
}

.Earn-page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  padding-bottom: calc(4.65rem + 3rem);
  padding-top: 0.65rem;
}

.StakeModal-addLiquidity-link {
  font-size: 12px;
  color: #fbd365;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 1250px) {
  .StakeV2-Holdings-wrapper{
    width: 100%;
  }

  .StakeV2-cards {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    gap: 20px;
    height: 100%;
  }

  .StakeV2-card {
    height: 100%;
  }

  .StakeV2-Holdings-block {
    width: 100%;
    border-width: 1px 1px 1px 1px;
    max-width: 100%;
  }

  .StakeV2-ManageAssets-title-wrapper {
    margin-left: 0;
  }

  .StakeV2-ManageAssets-block {
    max-width: 100%;
    border-width: 1px 1px 1px 1px;
  }

  .StakeV2-card-ManageAssets-header-row {
    max-width: 100%;
  }

  .StakeV2-card-ManageAssets-header {
    gap: 20px;
  }

  /* .StakeV2-Vest-cards {
    margin-bottom: 20px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  } */

  /* .StakeV2-Vest-left-card {
    padding: 10px;
    max-width: 100%;
    border-width: 1px;
  }

  .StakeV2-Vest-right-card {
    padding: 20px;

  } */

  .StakeV2-Vest-card {
    max-width: 100%;
    border-width: 1px;
  }
}

/* @media (max-width: 1100px) {
  .StakeV2-Vest-cards {
    margin-bottom: 20px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .StakeV2-Vest-left-card {
    padding: 10px;
    max-width: 100%;
    border-width: 1px;
  }

  .StakeV2-Vest-right-card {
    padding: 20px;
    max-width: 100%;
    border-width: 1px;
  }
} */

@media (max-width: 1100px) {
  .StakeV2-Vest-card-value-block {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 900px) {
  /* .StakeV2-cards {
    grid-template-columns: 1fr;
  } */

  .StakeV2-content {
    min-height: 100vh;
  }

  .StakeV2-total-rewards-card {
    grid-row: 4;
  }

  .StakeV2-Page-title {
    font-size: 30px;
  }
}

@media (max-width: 850px) {
  .StakeV2-TotalRewards-block {
    flex-direction: column;
    height: 100%;
    gap: 20px
  }

  .StakeV2-TotalRewards-block-total-wrapper {
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
  }

  .StakeV2-TotalRewards-block-4-wrapper {
    max-width: 100%;
  }
}

@media (max-width: 750px) {
  .StakeV2-card-ManageAssets-hooter {
    flex-direction: column;
    gap: 0px;
  }

  .StakeV2-card {
    height: 100%;
  }

  .StakeV2-button-outline {
    width: 100%;
    text-align: center;
  }

  .StakeV2-Vest-card-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .StakeV2-Vest-card-title-wrapper {
    justify-content: center;
    margin-bottom: 0;
  }

  .StakeV2-Vest-card-value-block {
    padding-top: 0;
  }

  .StakeV2-Vest-card-value-rows-wrapper {
    max-width: 100%;
  }

  .StakeV2-Vest-card-value-row {
    max-width: 100%;
  }
}

@media (max-width: 700px) {
  .StakeV2-card-ManageAssets-header {
    flex-direction: column;
    gap: 10px;
  }
}

/* @media (max-width: 650px) {
  .StakeV2-Vest-card-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .StakeV2-Vest-card-title-wrapper {
    justify-content: center;
    margin-bottom: 0;
  }

  .StakeV2-Vest-card-value-block {
    padding-top: 0;
  }
} */

@media (max-width: 550px) {
  /* all *0.8 */

  .StakeV2-subtitle {
    font-size: 16px;
    line-height: 24px;
  }

  .StakeV2-cards {
    margin-bottom: 18px;
  }

  .StakeV2-block-title-wrapper {
    margin-bottom: 15px;
  }

  .StakeV2-yellow-rectangle-image {
    margin-right: 6px;
    width: 4px;
    height: 24px;
  }

  .StakeV2-title-image {
    width: 19px;
    height: 19px;
  }

  .StakeV2-block-title {
    font-size: 16px;
    line-height: 24px;
  }

  .StakeV2-Holdings-card-title-image {
    margin-right: 6px;
    width: 32px;
    height: 32px;
  }

  .StakeV2-Holdings-card-title {
    font-size: 16px;
    line-height: 24px;
  }

  .StakeV2-Holdings-card-price-image {
    width: 10px;
    height: 11px;
  }

  .StakeV2-Holdings-card-price-value {
    font-size: 16px;
  }

  .StakeV2-Holdings-card-APR-value {
    font-size: 14px;
    line-height: 18px;
  }

  .StakeV2-Holdings-wallet-esGMX {
    font-size: 13px;
    line-height: 19px;
  }

  .StakeV2-Holdings-wallet-esGMX-usd {
    font-size: 11px;
    line-height: 17px;
  }

  .StakeV2-Grey-16-Text {
    font-size: 13px;
    line-height: 19px;
  }

  .StakeV2-wallet-image {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  .StakeV2-wallet-title {
    font-size: 11px;
    line-height: 16px;
  }

  .StakeV2-Holdings-Multiplier-Points {
    font-size: 11px;
    line-height: 16px;
  }
/*
  .StakeV2-Holdings-card-tooltip-apr {
    background-color: #1e0839;
    width: 650px;
    height: 150px;
    padding: 20px;
  }

  .StakeV2-Holdings-card-tooltip {
    background-color: #1e0839;
    padding: 20px;
    border-radius: 5px;
  } */

  .StakeV2-card-row-title {
    font-size: 11px;
    line-height: 16px;
    color: #6b7c86;
  }

  /* .StakeV2-card-button-section {
    min-height: 50px;
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 10px;
  } */

  .StakeV2-button-solid {
    padding: 6px 26px;
    /* border-radius: 33px; */
    font-size: 11px;
    line-height: 16px;
  }

  .StakeV2-button-outline {
    padding: 6px 26px;
    /* border-radius: 33px; */
    font-size: 11px;
    line-height: 16px;
  }

  /* ??? */

  /* .StakeV2-card-ManageAssets {
    padding: 15px 21px 0 27px;
    gap: 12px
  } */

  .StakeV2-card-ManageAssets-header-row-title {
    font-size: 13px;
    line-height: 19px;
  }

  .StakeV2-card-ManageAssets-header-row-value {
    font-size: 14px;
  }

  .StakeV2-card-ManageAssets-header-row-value-total {
    padding-right: 13px;
    font-size: 14px;
    align-self: center;
  }

  .StakeV2-TotalRewards-block-total-title {
    font-size: 13px;
    line-height: 19px;
  }

  .StakeV2-TotalRewards-block-total-value {
    font-size: 16px;
    line-height: 19px;
  }

  .StakeV2-TotalRewards-block-title {
    font-size: 13px;
    line-height: 19px;
  }



  .StakeV2-TotalRewards-block-value {
    font-size: 10px;
    line-height: 14px;
  }

  .StakeV2-TotalRewards-block-value-usd {
    font-size: 13px;
    line-height: 19px;
  }

  .StakeV2-TotalRewards-block-4-row-title {
    font-size: 13px;
    line-height: 19px;
  }

  .StakeV2-TotalRewards-block-4-row-value {
    font-size: 13px;
    line-height: 15px;
  }

  .StakeV2-Tab-title-section {
    margin-bottom: 12px;
  }

  .StakeV2-Vest-card-wrapper {
    gap: 5px
  }

  .StakeV2-Vest-card-title-wrapper {
    margin-bottom: 14px;
  }

  .StakeV2-Vest-card-title-image-wrapper {
    margin-right: 5px;
  }

  .StakeV2-Vest-card-title-image {
    width: 32px;
    height: 32px;
  }

  .StakeV2-Vest-card-title-image-vmxftm {
    width: 32px;
    height: 44px;
  }

  .StakeV2-Vest-card-title {
    font-size: 16px;
    line-height: 24px;
  }

  .StakeV2-wallet-button {
    padding: 6px 12px;
    border-radius: 33px;
    font-size: 10px;
    line-height: 16px;
  }



  .StakeV2-Vest-card-value-title {
    font-size: 13px;
    line-height: 19px;
  }

  .StakeV2-Vest-card-value-value {
    font-size: 13px;
    line-height: 15px;
  }
}

@media (max-width: 500px) {
  .StakeV2-Page-title {
    font-size: 28px;
  }

  .StakeV2-subtitle {
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  .StakeV2-card-ManageAssets-header-row-value-total {
    font-size: 11px;
    padding-right: 0;
  }
}

@media (max-width: 370px) {
  .StakeV2-card-ManageAssets-header-row-value-total {
    font-size: 10px;
    padding-right: 0;
  }
}

