.chertTokenSelectorValues {
  display: flex;
  align-items: center;
}

.chertTokenSelectorValues__images {
  margin-right: 8px;
  width: 38px;
  height: 19px;
  /* margin-right: 8px;
  width: 48px;
  height: 24px; */
  display: flex;
  align-items: center;
}

.chertTokenSelectorValues__image {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
}

.ChartTokenSelectorValues__image_move {
  position: relative;
  left: 10px;
}

@media (max-width: 1170px) {
  .chertTokenSelectorValues__images {
    margin-right: 0;
  }
  .ChartTokenSelectorValues__image_move {
    position: static;
  }
}

@media (max-width: 1100px) {
  .chertTokenSelectorValues__images {
    margin-right: 8px;
  }
  .ChartTokenSelectorValues__image_move {
    position: relative;
    left: 10px;
  }
}

@media (max-width: 760px) {
  .chertTokenSelectorValues__images {
    margin-right: 0;
  }
  .ChartTokenSelectorValues__image_move {
    position: static;
  }
}

@media (max-width: 700px) {
  .chertTokenSelectorValues__images {
    margin-right: 2px;
  }
  .ChartTokenSelectorValues__image_move {
    position: relative;
    left: 10px;
  }
}

@media (max-width: 390px) {
  .chertTokenSelectorValues__images {
    width: 32px;
  }
  /* .ChartTokenSelectorValues__image_move {
    position: static;
  } */

  .chertTokenSelectorValues__image {
    width: 16px;
    height: 16px;
  }
}