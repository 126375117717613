/* .Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: var(--font-sm);
  border-radius: 3px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.7);
  background: linear-gradient(90deg, rgba(30, 34, 61, 0.9) 0%, rgba(38, 43, 71, 0.9) 100%);
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
}

.Tab.block .Tab-option:hover {
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(90deg, rgba(35, 57, 172, 1) 0%, rgba(43, 59, 147, 1) 100%);
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(45, 66, 252, 1) 0%, rgba(46, 61, 205, 1) 100%);
  color: #fefdff;
  border-radius: 16px;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-base);
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
} */

.TabWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  border-bottom: 1px solid #7120c8;
}

.TabItem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fefdff;

  font-size: 12px;

  padding: 8px;
  /* width: 325px; */
  height: 32px;
  /* height: 40px; */
  /* background: #1E0839;
  border-width: 1px;
  border-style: solid;
  border-color: #7120C8; */
} 

.activeTabItem {
  background: #1e0839;
  border-width: 0px 1px;
  border-style: solid;
  border-color: #7120c8;
}

.Tab-option-icon {
  margin-right: 11px;
  opacity: 0.7;
}