.Exchange {
  /* max-width: 155rem; */
  margin: auto;
  justify-content: space-between;
}

.Exchange-left {

}

.Exchange-left-chart-wrapper {
  border-left: 1px solid #581DAD;
  border-right: 1px solid #581DAD;
  height: 515px;
  /* height: 630px; */
}

.Exchange-box-highlight {
  height: 515px;
  /* height: 630px; */
  position: relative;
  border: 1px solid #581dad;
  /* border-radius: 20px; */
  /* background: #0d0417; */
  background: rgba(113, 32, 200, 0.44);
  font-size: 1.2rem;
  /* font-size: var(--font-base); */
  overflow: auto;

}

.Exchange-box-highlight::-webkit-scrollbar {
  width: 3px;
}

.Exchange-box-highlight::-webkit-scrollbar-track {
  background: #6b7c86;
}

.Exchange-box-highlight::-webkit-scrollbar-thumb {
  background: #581dad;
}

.Exchange-trigger-order-info,
.Exchange-trigger-order-info a {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid #23263b;
}

.Exchange-list-tab-container {
  /* display: grid;
  grid-template-columns: auto auto;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1.05rem; */
}

.Exchange-should-show-position-lines {
  font-size: var(--font-sm);
}
.Exchange-should-show-position-lines .active {
  opacity: 1;
}

.Exchange-empty-positions-list-note {
  padding-bottom: 0.465rem;
}

.position-loading-icon {
  display: inline-block;
  font-size: 1.25rem;
  margin-left: 0.8rem;
  color: #3749e6;
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Exchange-settings-row {
  display: flex;
  flex-direction: row-reverse;
  align-items:center ;

  margin-bottom: 0.8rem;
}

.Exchange-settings-row span {
  margin-right: 5px;
}

.Exchange-settings .App-cta {
  margin-top: 1.5rem;
}

.Exchange-content {
  display: grid;
  grid-template-columns: 1fr auto;
  /* grid-gap: 1.5rem; */
  padding: 3.1rem 0;
  padding-top: 0rem;
  margin-top: -1.5rem;
}

.Exchange-position-list-orders {
  white-space: nowrap;
}

.Exchange-lists.large {
  display: block;
}

.Exchange-lists.small {
  display: none;
}

.Exchange-right {
  display: grid;
  grid-template-rows: auto 1fr;
  border-bottom: 1px solid #7120c8;
  border-right: 1px solid #7120c8;
}

.Exchange-leverage-slider-settings {
  font-size: var(--font-sm);
  margin-bottom: 0.8rem;
}

.Exchange-leverage-slider-settings .Checkbox {
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
}

.Exchange-wallet-tokens {
  position: relative;
  min-height: 15.5rem;
  display: none;
}

.Exchange-wallet-tokens-content {
  position: absolute;
  top: 1.5rem;
  bottom: 0;
  left: 0;
  right: 0;
}

.Exchange-bottom-header-item {
  display: inline-block;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 0.155rem;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-bottom-header-item.active {
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-connect-wallet {
  text-align: center;
  cursor: pointer;
  border: 1px solid rgb(50, 50, 50);
  border-radius: 3px;
}

.Exchange-swap-connect-wallet:hover {
  background: #272e33;
  border-radius: 0.6rem;
}

.Exchange-swap-settings.Exchange-swap-txns-status {
  text-align: right;
  margin-right: 1.5rem;
}

.Exchange-swap-account .Exchange-swap-address:hover,
.Exchange-swap-account .Exchange-swap-txns-status:hover {
  opacity: 1;
}

.Exchange-leverage-slider {
  margin-top: 0.8rem;
  margin-bottom: 2.2rem;
  /* margin-top: 1.5rem;
  margin-bottom: 3.4rem; */
  padding: 0 0.8rem;
}

.Exchange-swap-leverage-options {
  text-align: right;
}

.Exchange-swap-leverage-option {
  display: inline-block;
  margin-left: 0.8rem;
  padding: 0 0.8rem;
  border-radius: 3px;
  background: #5b2ab0;
  color: white;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-swap-leverage-option.active {
  background: #842ec9;
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-leverage-option:hover {
  opacity: 1;
}

.Exchange-swap-leverage-unlock-icon {
  font-size: 0.8rem;
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Exchange-swap-placeholder {
  height: 11.175rem;
  position: relative;
}

.Exchange-wave {
  z-index: 1;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  top: 1.5rem;
  bottom: 1.5rem;
  mask-image: url(img/wave-01.png);
  mask-size: 90% 90%;
  mask-repeat: no-repeat;
  mask-position: center;
  filter: blur(5rem);
  background: linear-gradient(
    40deg,
    rgba(96, 4, 189, 1) 0%,
    rgba(65, 105, 224, 1) 22%,
    rgba(99, 8, 191, 1) 23%,
    rgba(51, 9, 115, 1) 37%,
    rgba(255, 36, 145, 1) 38%,
    rgba(201, 16, 161, 1) 50%,
    rgba(171, 10, 163, 1) 59%,
    rgba(117, 4, 128, 1) 67%,
    rgba(96, 29, 196, 1) 68%,
    rgba(92, 55, 204, 1) 84%,
    rgba(84, 88, 214, 1) 100%
  );
  pointer-events: none;
}

.PositionEditor-info-box {
  margin-bottom: 1.05rem;
}

.PositionEditor-keep-leverage-settings .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.PositionEditor-accept-profit-warning .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.PositionEditor-allow-higher-slippage .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.ExchangeChart-range-option {
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-sm);
  cursor: pointer;
  opacity: 0.6;
}

.ExchangeChart-range-option:hover {
  opacity: 0.8;
}

.ExchangeChart-range-option.active {
  opacity: 1;
}

.ExchangeChart {
  position: relative;
  height: 100%;
  /* height: 49.6rem; */
}

.ExchangeChart-main-price {
  font-size: 1.9rem;
  /* font-size: 2.4rem; */
  font-weight: 500;
  letter-spacing: 0.5px;
}

.ExchangeChart-title {
  font-size: 2.15rem;
  font-weight: bold;
}

.ExchangeChart-info-label {
  font-size: 1rem;
  /* font-size: 1.25rem; */
  opacity: 0.7;
}

.ExchangeChart-top-inner {
  min-height: 53px;
  display: grid;
  grid-template-columns: auto auto auto auto auto 1fr;
  grid-column-gap: 4.65rem;
  align-items: center;
  padding-left: 0.3rem;
}

.ExchangeChart-dollar-sign {
  display: inline-block;
  margin-right: 0.465rem;
  opacity: 0.7;
}

.ExchangeChart-main-price-text {
  display: inline-block;
}

.ExchangeChart-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  z-index: 2;
}

.ExchangeChart.tv .ExchangeChart-top {
  position: relative;
  padding: 0.9rem 0.31rem;
  z-index: 2;
}

.ExchangeChart.tv .ExchangeChart-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 6.975rem;
  z-index: 1;
}
.ExchangeChart-bottom-controls {
  display: inline-block;
}
.ExchangeChart-bottom-controls .Tab .Tab-option {
  width: 4.65rem;
  padding: 0.5rem 0;
}
.ExchangeChart.tv .ExchangeChart-bottom-header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 5;
}
.ExchangeChart.tv .ExchangeChart-bottom-stats {
  font-size: var(--font-sm);
  padding: 0.8rem 0;
  margin-left: 4rem;
  white-space: nowrap;
  z-index: 2;
}
.ExchangeChart-bottom-stats-label {
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
}
.ExchangeChart-bottom-stats-value {
  display: inline-block;
  margin-left: 0.31rem;
  margin-right: 0.8rem;
}
.length-5 .ExchangeChart-bottom-stats-value {
  width: 6.2rem;
}
.length-4 .ExchangeChart-bottom-stats-value {
  width: 5.25rem;
}
.length-3 .ExchangeChart-bottom-stats-value {
  width: 4.35rem;
}
.length-2 .ExchangeChart-bottom-stats-value {
  width: 3.41rem;
}
.length-1 .ExchangeChart-bottom-stats-value {
  width: 2.48rem;
}

.ExchangeChart.tv .ExchangeChart-bottom-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.Exchange-price-tooltip {
  font-size: var(--font-sm);
  background: linear-gradient(90deg, rgba(57, 28, 147, 0.9) 0%, rgba(66, 24, 140, 0.9) 100%);
  padding: 0.9rem;
  border: 1px solid rgba(30, 9, 94, 0.5);
  line-height: 2rem;
}

.Exchange-price-time {
  font-size: 1.25rem;
  font-weight: normal;
}

.Error-modal .Modal-content {
  width: 31rem;
}

.PositionEditor .Modal-content {
  width: 38rem;
  position: absolute;
  max-height: 80vh;
  overflow: hidden;
  border: none;
  top: auto;
  bottom: auto;
}

.PositionEditor .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 8.525rem);
  overflow-y: auto;
  padding-right: 0.5rem;
  margin: 1.5rem;
  margin-right: 1rem;
}

.PositionEditor .Tab {
  margin-bottom: 1.05rem;
}

.PositionEditor-token-symbol {
  font-size: 2.1rem;
  text-align: right;
}

.ExchangeChart-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Exchange-list-modal .Modal-content {
  width: 36rem;
}

.Exchange-list {
  width: 100%;
}

.Position-list-order {
  white-space: nowrap;
  margin-top: 8px;
}

.active-order-tooltip {
  background: var(--dark-blue-hover);
  padding: 0.75rem;
  border-radius: var(--border-radius-sm);
  white-space: normal;
}
.active-order-tooltip > .active-oredr-error {
  margin-top: 0.5rem;
}

.order-error span {
  text-decoration-color: var(--error-red);
  color: var(--error-red);
}

.order-error .Tooltip-popup {
  white-space: pre-line;
}

.Exchange-list .App-card {
  margin-bottom: 1.5rem;
}

.Exchange-list-item-error {
  color: #fa3c58;
  margin-top: 0.465rem;
  font-size: var(--font-sm);
}

.Exchange-list.small {
  display: none;
}

button.Exchange-list-action {
  font-size: 12px;
  /* font-size: var(--font-base); */
  padding: 0;
  border-radius: 3px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0.3rem;
}

button.Exchange-list-action:hover {
  color: rgba(255, 255, 255, 1);
}

table.Exchange-list {
  border-collapse: collapse;
}

table.Exchange-list th,
table.Exchange-list td {
  font-weight: normal;
  text-align: left;
  padding: 0.5rem;
  padding-left: 1.5rem;
  font-size: 12px;
}
table.Exchange-list.Orders th,
table.Exchange-list.Orders td {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

table.Exchange-list tr {
  border-bottom: 1px solid #581dad;
}

table.Exchange-list tr:last-child {
  border-bottom: none;
}

table.Exchange-list tr:hover {
  background: #591dad5b;
  /* background: linear-gradient(90deg, rgba(30, 34, 61, 1) 0%, rgba(38, 43, 71, 1) 100%); */
  box-shadow: inset 0px 0px 3rem 0.5rem rgba(255, 255, 255, 0.01);
}

table.Exchange-list tr.Exchange-list-header {
  background: none;
  font-size: 13px;
  /* font-size: var(--font-md); */
}

table.Exchange-list tr.Exchange-list-header th {
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  /* padding-top: 1.4rem;
  padding-bottom: 1.4rem; */
}

table.Exchange-list th {
  opacity: 0.7;
}

.Exchange-leverage-box {
  padding: 0 21px 0 17px;
  margin-bottom: 0.8rem;
  /* margin-bottom: 1.05rem; */
}

.Exchange-swap-box-info {
  margin-bottom: 1.05rem;
  padding: 0 26px 0 17px;
}

.Exchange-info-row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 1.1rem;
  /* font-size: var(--font-sm); */
  margin-bottom: 0.465rem;
}



.Exchange-info-row.top-line {
  border-top: 1px solid #23263b;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.Exchange-info-label {
  /* opacity: 0.7; */
  margin-right: 0.8rem;
}
.Exchange-info-value-warning {
  color: #fa3c58;
}

.Confirmation-box-info,
.Confirmation-box-info a,
.Confirmation-box-warning,
.Confirmation-box-warning a {
  font-size: var(--font-sm);
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Confirmation-box-info,
.Confirmation-box-info a {
  opacity: 0.7;
}

.Confirmation-box-warning,
.Confirmation-box-warning a {
  color: #fa3c58;
}

.Exchange-swap-usd {
  display: inline-block;
  font-size: 12px;
  /* font-size: var(--font-sm); */
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 4px;
  overflow: hidden;
  /* max-width: 128px; */
  width: 100%;
}

.Exchange-swap-usd-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Exchange-swap-usd-V2 {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #fbd365;
}

.Exchange-swap-usd-value {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #97adba;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 175px;
}

.Exchange-swap-box {
  height: 100%;
  width: 41.85rem;
  display: grid;
  position: relative;
}

.Exchange-swap-market-box-title {
  color: #fefdff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* font-size: 20px;
  line-height: 30px; */
  /* font-size: var(--font-md); */
  /* margin-bottom: 1.25rem; */
}

.Exchange-swap-box-inner {
  padding: 1.5rem;
}

.Exchange-swap-market-box {
  /* min-height: 310px; */
  height: 100%;
  padding: 0.5rem 1.5rem;
  /* margin-top: 1.25rem; */
  /* padding-bottom: 1.5rem; */
  border-bottom: none;
  border-right: 1px solid #581DAD;
}

.Exchange-swap-market-box .App-card-divider {
  margin: 0 -1.5rem 1.5rem;
}

.Exchange-swap-box .Radio {
  margin-bottom: 1.05rem;
}

.Exchange-swap-box .Overlay-content-outer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15.5rem;
}

.Exchange-swap-box .Overlay-content {
  padding: 1.05rem;
}

.Exchange-swap-box .TokenSelector .Modal,
.Selector .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.Exchange-swap-box .TokenSelector .Modal-content,
.Selector .Modal-content {
  width: 41.85rem;
  position: absolute;
  max-height: 100vh;
  top: 0;
  bottom: 0;
  overflow: hidden;
  border: none;
}
.Exchange-swap-box .TokenSelector .Modal-content .Modal-body {
  scroll-margin-right: 38.75rem;
  overscroll-behavior: smooth;
  scrollbar-width: thin;
  padding-right: 0.5rem;
  max-height: calc(100vh - 7.75rem);
  overflow-y: auto;
}

.Exchange-swap-box .Tab {
  margin-bottom: 1.05rem;
}

.Exchange-swap-section {
  padding: 22px 24px;
  margin-bottom: 1.05rem;
  border: 1px solid #581dad;
  border-radius: 2px;
  background: #1e0839;
  box-shadow: inset 0px 0px 3rem 0.5rem rgba(255, 255, 255, 0.01);
}

.Exchange-swap-option-tabs.Tab.block .Tab-option {
  padding: 1.05rem;
}

.Exchange-swap-order-type-tabs {
  margin-top: 1.5rem;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option-icon {
  margin-top: -0.155rem;
  transform: scale(0.75);
  vertical-align: middle;
  margin-right: 0.8rem;
}

.Exchange-swap-section-top {
  display: grid;
  grid-template-columns: auto auto auto;
  font-size: var(--font-sm);
  padding-bottom: 1.25rem;
}

.Exchange-swap-section-bottom {
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: 0.31rem;
}

.Exchange-swap-section-bottom3 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Exchange-swap-section-bottom-PriceSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Exchange-swap-section-bottom-PriceSection .Exchange-swap-input {
  font-size: 16px;
}

.Exchange-swap-message {
  font-size: var(--font-sm);
  opacity: 0.7;
  margin: 0.5rem 0;
}

.Exchange-swap-input-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  overflow: hidden;
}

.Exchange-swap-max {
  position: absolute;
  right: 5px;
  top: 0;
  padding: 0.465rem 0.8rem;
  color: #fbd365;
  border-radius: 3px;
  z-index: 1;
  cursor: pointer;
  font-size: var(--font-sm);
  background: #7120C8;
  /* background: linear-gradient(90deg, rgba(45, 66, 252, 1) 0%, rgba(46, 61, 205, 1) 100%); */
}

.Exchange-swap-max:hover {
  color: rgba(255, 255, 255, 1);
  background: #7120c8ee;
  /* background: linear-gradient(90deg, rgba(58, 78, 252, 1) 0%, rgba(51, 68, 222, 1) 100%); */
}

.Exchange-swap-section-bottom .TokenSelector-box {
  font-size: 13px;
  /* font-size: 16px; */
}

.Exchange-leverage-box .TokenSelector-caret {
  font-size: var(--font-base);
  margin-left: 0.31rem;
}

input.Exchange-swap-input {
  padding: 0;
  max-width: 100%;
  font-size: 19px;
  /* font-size: 24px; */
  /* font-size: var(--font-lg); */
  width: 100%;
  /* text-align: right; */
  padding-right: 4px;
  letter-spacing: 0.5px;
  text-overflow: clip;
}
input.Exchange-swap-input::placeholder {
  color: #fefdff;
  opacity: 1;
}

input.Exchange-swap-input.small {
  max-width: 14.725rem;
}

.Exchange-swap-button-container {
  padding-top: 0.31rem;
}

.Exchange-swap-button {
  display: block;
  width: 100%;
}

.Exchange-swap-ball-container {
  position: relative;
  z-index: 1;
}

.Exchange-swap-ball-icon {
  text-align: center;
  display: block;
  transform: rotate(90deg);
  font-size: 2rem;
  opacity: 0.7;
}

.Exchange-swap-ball {
  position: absolute;
  width: 3.565rem;
  height: 3.565rem;
  left: 50%;
  margin-left: -1.7825rem;
  top: -1.9375rem;
  border-radius: 3.1rem;
  cursor: pointer;
  user-select: none;
  background: #7120c8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fbd365;
}

.Exchange-swap-ball:hover {
  background: #7120c8de;
}

.Exchange-swap-ball:hover .Exchange-swap-ball-icon {
  color: #fbd365ce;
  opacity: 1;
}

table.Exchange-list-small {
  width: 100%;
  background: linear-gradient(45deg, rgba(11, 5, 55, 0.6) 0%, rgba(21, 3, 48, 0.6) 100%);
}

table.Exchange-list-small th,
table.Exchange-list-small td {
  font-weight: normal;
  text-align: left;
  padding: 1.05rem;
}

.Exchange-list-card-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0.8rem;
}

.Exchange-list-title {
  width: 3.1rem;
  display: inline-block;
  white-space: nowrap;
}

.Exchange-list-info-label {
  font-size: 11px;
  /* font-size: var(--font-sm); */
  margin-top: 0.24rem;
  /* margin-top: 0.31rem; */
}

.Exchange-list-muted {
  font-size: var(--font-sm);
}

.Exchange-list-card .Exchange-list-side {
  text-align: right;
}

.Exchange-list-card .Exchange-list-size-info {
  margin-bottom: 0.8rem;
}

.Exchange-PositionDropdown-position {
  top: -5rem;
  right: -15rem;
}

.Confirmation-box {
  font-size: 2rem;
}

.Confirmation-box-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 2.325rem;
}
.Confirmation-box-main-icon {
  margin: 0.5rem 0;
}
.Confirmation-box-main-icon:after {
  content: "↓";
}
.Confirmation-box-main-icon.dot:after {
  content: "•";
}

.Confirmation-box-main-row {
  display: flex;
  justify-content: space-between;
}

.Confirmation-box-main-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fefdff;
  display: flex;
  align-items: center;
}

.Confirmation-box-main-image {
  width: 20px;
  height: 20px;
}

.Confirmation-box-main-value {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: #fefdff;
  display: flex;
}

.Confirmation-box-main-value-yellow {
  color: #fbd365;
  margin-left: 5px;
}

.Confirmation-box .Modal-content {
  width: 36rem;
}

.Confirmation-box-row {
  margin-top: 1.5rem;
}

.Confirmation-box-button {
  margin-top: 1rem;
  width: 100%;
}

.Exchange-footer {
  text-align: center;
  padding-top: 3.1rem;
  height: 7.75rem;
}
.cancel-order-btn {
  margin-right: 1.5rem;
  background: none;
  border: none;
  color: white;
  padding: 0;
}
.cancel-order-btn:hover {
  opacity: 0.8;
}
.cancel-order-btn:disabled {
  opacity: 0.6;
}

.chart-positions:hover {
  opacity: 0.8;
}
.chart-positions.span:hover {
  opacity: 1;
}

.al-swap .Tooltip-popup {
  min-width: 25rem;
}

.position-list-collateral {
  display: flex;
  align-items: flex-end;
}

.position-list-collateral .edit-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  opacity: 0.7;
}
.position-list-collateral .edit-icon:hover {
  opacity: 1;
}

.Useful-links-exchange {
  display: none;
}

.ExchangeChart-error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Position-leverage {
  margin-right: 0.25rem;
}


/*-----------------------------------------------------*/


.Exchange-box {
  position: relative;
  border-top: 1px solid #581DAD;
  /* border-bottom: 1px solid #581DAD; */
  /* border-radius: 20px; */
  /* border: 1px solid #581DAD; */
  /* background: #120124; */
  font-size: var(--font-base);
}

.ExchangeChart-bottom-wrapper {
  padding: 10px;
}

.Exchange-list-section-wrapper {
  /* margin-top: 20px;
  padding: 15px 20px; */
  min-height: 190px;
  background: #0d0417;
  border: 1px solid #7120c8;
}

.Exchange-swap-order-type-tabs-wrapper {
  padding: 5px 21px 0 17px;
  width: 100%;
  margin-bottom: 5px;
}

.Exchange-swap-balance-block-wrapper {
  margin-bottom: 8px;
  /* margin-bottom: 10px; */
  padding: 0 26px 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Exchange-swap-balance-title {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* font-size: 12px;
  line-height: 18px; */
  letter-spacing: 0.5px;
  color: #fefdff;
}

.Exchange-swap-balance-value {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* font-size: 12px;
  line-height: 18px; */
  letter-spacing: 0.5px;
  color: #fefdff;
}

.Exchange-swap-block-wrapper {
  margin-bottom: 8px;
  /* margin-bottom: 10px; */
  padding: 0 21px 0 17px;
  display: flex;
  flex-direction: column;
  gap: 3px
}

.Exchange-swap-section-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Exchange-swap-section2 {
  padding: 3px 12px;
  /* padding: 4px 15px; */
  /* margin-bottom: 1.05rem; */
  border: 1px solid #581dad;
  border-radius: 2px;
  background: #1e0839;
  box-shadow: inset 0px 0px 3rem 0.5rem rgba(255, 255, 255, 0.01);
}

.Exchange-swap-section-bottom2 {
  /* display: grid;
  grid-template-columns: 1fr 1fr auto;
  padding-bottom: 0.31rem; */
  display: flex;
  flex-direction: column;
}

.Exchange-swap-section-bottom2 .TokenSelector-box {
  font-size: 13px;
  /* font-size: 16px; */
}

.Exchange-swap-section-title {
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  /* font-size: 16px;
  line-height: 24px; */
  color: #fefdff;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.Exchange-swap-button-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Exchange-swap-token-selector-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  /* font-size: 16px;
  line-height: 24px; */
  letter-spacing: 0.5px;
  color: #fefdff;
}

.Exchange-swap-button-image {
  width: 16px;
  height: 16px;
  /* width: 20px;
  height: 20px; */
  cursor: pointer;
}

.Exchange-swap-section-top2 {
  /* display: grid;
  grid-template-columns: 1fr 1fr auto;
  font-size: var(--font-sm);
  padding-bottom: 0.31rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Exchange-swap-section-top2 .TokenSelector-box {
  font-size: 13px;
  /* font-size: 16px; */
}

.Exchange-swap-section-top3 {
  /* display: grid;
  grid-template-columns: 1fr auto;
  font-size: var(--font-sm);
  padding-bottom: 0.31rem; */
  display: flex;
  align-items: center;
  gap: 5px;
}

.Exchange-swap-section-top3 .Exchange-swap-input {
  font-size: 13px;
  /* font-size: 16px; */
}

.Exchange-swap-section-leverage {
  margin-right: 10px;
  display: inline-block;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  /* font-size: 13px;
  line-height: 20px; */
  color: #fbd365;
}

.Exchange-swap-section-leverage-value {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  /* font-size: 13px;
  line-height: 20px; */
  color: #fefdff;
}

.Exchange-leverage-slider-settings-title {
  padding-left: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  /* font-size: 16px;
  line-height: 24px; */
  color: #fefdff;
}

.Exchange-leverage-slider-value {
  color: red;
}

.Exchange-swap-button-custom {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  padding: 1.2rem;
  /* padding: 1.5rem; */
  border-radius: 30px;
  border: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* font-size: 16px;
  line-height: 20px; */
  /* letter-spacing: 0.5px; */
  color: #fefdff;
  text-decoration: none;
  background: #7120c8;
}

.Exchange-swap-button-custom:hover:enabled {
  background: #7120c8ce;
}

.Exchange-swap-button-custom:active:enabled {
  background: #7120c8cb;
}

.Exchange-swap-button-custom:disabled {
  background: #9873c0ce;
}

.Exchange-position-button-custom {
  cursor: pointer;
  border-radius: 4px;
  font-size: var(--font-sm);
  line-height: 2rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #fefdff;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 36px;
  display: inline-flex !important;
  align-items: center;
  border: 1px solid #7120c8;
  background: inherit;
}

.Exchange-swap-section-price-value {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #97adba;
}

.Exchange-swap-section-price {
  display: inline-block;
  color: #fbd365;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-right: 10px;
}

.PositionEditor-token-symbol2 {
  font-size: 16px;
  text-align: right;
}

.ExchangeChart.tv .ExchangeChart-exchange-checkbox {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 5;
}

.Exchange-positions-card-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.Exchange-positions-card {
  /* margin-left: 1rem; */
  position: relative;
  border-bottom: 1px solid #7120c8;
  border-radius: 0.4rem;
  font-size: var(--font-base);
  padding: 5px;
}

.Exchange-swap-confirmation-box-wrapper {
  padding: 0 24px 34px 17px;
}

.Exchange-swap-button-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 21px 5px 17px;
}

.Exchange-swap-button-container2-image {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  /* width: 40px;
  height: 40px;
  margin-right: 10px; */
}

.Exchange-swap-market-box-title-wrapper {
  display: flex;
  margin-bottom: 8px;
  margin-top: 5px;
}

.Exchange-swap-SwapYellowRectangleImage {
  margin-right: 5px;
  width: 4px;
  height: 24px;
  /* margin-right: 6px;
  width: 5px;
  height: 30px; */
}

.Exchange-swap-market-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  /* margin-right: 6px; */
}

.Exchange-info-row-wrapper {
  height: 24px;
  /* height: 30px; */
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  /* font-size: 16px;
  line-height: 24px; */
  letter-spacing: 0.5px;
  color: #fff;
}

.Exchange-info-row-title {
  color: #6b7c86;
}

.Exchange-swap-SwapYellowMarketImage {
  width: 16px;
  height: 16px;
  /* width: 20px;
  height: 20px; */
}

.Exchange-trigger-section-wrapper {
  padding: 0 24px 0 17px;
}

.Exchange-empty-positions-list-note-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100px;

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #ebe2dd;
}

.Exchange-empty-positions-list-note-row-trades {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100px;

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #ebe2dd;
}

.Exchange-page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: calc(4.65rem);
  padding-top: 1.5rem;
}

.Exchange-swap-order-wrapper {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.Exchange-swap-order-title {
  /* margin-bottom: 10px; */
  font-weight: 400;
  font-size: 10px;
  /* font-size: 12px; */
  line-height: 14px;
  /* line-height: 18px; */
  letter-spacing: 0.5px;
  color: #fefdff;
}

.ExchangeChart-main-price-image {
  width: 17px;
  height: 20px;
  margin-left: 8px;
}

.Exchange-info-tooltip {
  padding: 10px;
  background-color: #1e0839;
}

.Exchange-card-divider {
  height: 1px;
  background: #7120c8;
  margin: 1.05rem -0.5rem;
}



@media (max-width: 1500px) {
  .Exchange-swap-box {
    width: 38.75rem;
  }
}

@media (max-width: 1400px) {
  .ExchangeChart-main-price {
    font-size: 2.2rem;
  }
}

@media (max-width: 1200px) {
  .Exchange-list.large {
    display: none;
  }

  .Exchange-list.small {
    display: table;
  }
}

@media (max-width: 1190px) {
  .Exchange-left-chart-wrapper {
    height: 100%;
  }

  .Exchange-box-highlight {
    height: 100%;
  }

  /* .Exchange-swap-market-box {
    border-left: 1px solid #581DAD;
  } */

  .Exchange-swap-market-box {
    border-left: 1px solid #581DAD;
  }

  .Exchange-swap-box {
    width: auto;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-header {
    display: block;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-stats {
    margin-left: 0;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  .Exchange-list-tabs {
    margin-top: 0;
  }

  .Exchange-wallet-tokens {
    display: none;
  }

  .Exchange-swap-placeholder {
    display: none;
  }

  .ExchangeChart {
    height: 38.75rem;
  }

  .Exchange-content {
    grid-template-columns: 1fr;
  }

  .Exchange-lists.large {
    display: none;
  }

  .Exchange-lists.small {
    display: block;
  }

  .Exchange-swap-box .TokenSelector .Modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .order-error-message {
    white-space: pre-line;
    display: block;
    margin-bottom: 10px;
  }

  .Exchange-swap-box .TokenSelector .Modal-content,
  .Exchange-swap-box .Selector .Modal-content {
    /* display: block;
    margin: 0;
    padding: 1.5rem;
    box-sizing: border-box;
    height: auto;
    border: 1px solid #1c0e6a;
    width: 100%; */
  }

  .Exchange-swap-box .TokenSelector .Modal-content,
  .Selector .Modal-content {
    width: 41.85rem;
    position: absolute;
    max-height: 80vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }

  .Exchange-swap-box .TokenSelector .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(70vh - 8.525rem);
    scroll-margin-right: 38.75rem;
    overscroll-behavior: smooth;
    scrollbar-width: thin;
    padding-right: 0.5rem;
    margin: 1.5rem 0.8rem 1.5rem 1.5rem;
  }

  .Exchange-swap-box .TokenSelector .Modal,
  .Selector .Modal {
    align-items: center;
    justify-content: center;
  }

  input.Exchange-swap-input {
    /* max-width: 15.5rem; */
  }

  .ExchangeChart-bottom-controls .Tab .Tab-option {
    width: 3.875rem;
    padding: 0.465rem 0;
    font-size: 1.25rem;
  }
  .Useful-links-swapbox {
    display: none;
  }
  .Useful-links-exchange {
    display: block;
  }

  .ExchangeChart-main-price {
    font-size: 2rem;
  }
}

@media (max-width: 700px) {
  .PositionEditor .Modal-content {
    width: 90vw;
  }

  .ExchangeChart-top-inner {
    grid-template-columns: auto auto auto;
    grid-column-gap: 0.8rem;
  }

  .ExchangeChart-title {
    font-size: 1.6rem;
  }

  .ExchangeChart-additional-info {
    display: none;
  }

  .Exchange-content {
    /* padding: 1.5rem; */
    /* padding-top: 1.5rem; */
  }
  .Exchange-swap-box .TokenSelector .Modal {
    margin: 0 1.5rem;
  }
  .Exchange {
    padding-top: 1.5rem !important;
  }

  .Exchange-positions-card {
    padding-bottom: 10px;
  }

  .ExchangeChart-main-price {
    font-size: 1.6rem;
  }
}
