.Component-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;

}

.Table-title {
  color: #fff;
  text-align: center;
  font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 2%;
}

.Table-container {
  overflow-x: auto;
  margin-left: 5%;
  margin-right: 5%;
}

.Airdrop-table {
  white-space: nowrap;
  border-collapse: collapse;
  min-width: 100%;
  min-height: 250px;
}

.Airdrop-th, .Airdrop-td {
  border: 1px solid #dfc437;
  padding: 8px;
  text-align: left;
}

.Airdrop-claim-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.Airdrop-timer {
  font-size: 22px;
  color: #dfc437;
}