/* @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
#container {
  margin: 1rem;
}

.items {
  margin-bottom: 1rem;
} */

.Stats-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Make vertical, add margins for vertical layout */
@media (max-width: 600px) {
  .Stats-container {
    flex-direction: column;
  }

  .Stats-item, .Stats-item-profit, .stats-item-loss {
    margin-bottom: 10px;
  }
}

.Stats-item {
  text-align: center;
}

.Stats-label {
  font-size: 16px;
}

.Stats-value {
  font-size: 20px;
  font-weight: bold;
}

.Stats-value-profit {
  font-size: 20px;
  font-weight: bold;
  color: #16d156;
}

.Stats-value-loss {
  font-size: 20px;
  font-weight: bold;
  color: #df1417;
}

.Leaderboard-container {
  padding-bottom: 3.1rem;
  width: 100%;
}

.Leaderboard-title-block-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.Leaderboard-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Leaderboard-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -2px;
  color: #fff;
}

.Competition-timer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh; /* Adjust as needed */
}

.Competition-timer {
  font-size: 24px;
}

.Leaderboard-sort-dropdown-wrapper {
  display: none;
  width: 155px;
  padding: 4px 8px;
  position: relative;
  background: #120124;
  border: 1px solid #7120c8;
  border-radius: 2px;
}

.Leaderboard-sort-dropdown-button-label {
  width: 100%;
}

.Leaderboard-button-outline {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 175px;
  padding: 8px 32px;
  border: 1px solid #7120c8;
  border-radius: 33px;
  background: inherit;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #fefdff;
  text-decoration: none;
}

.Leaderboard-button-outline:hover {
  background: #7120c8;
  color: #fff;
  transition: 0.2s ease-in-out;
}

.Leaderboard-title-search-block-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.Leaderboard-title-search-wrapper {
  width: 100%;
  min-width: 360px;
  position: relative;
}

.Leaderboard-title-search {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  background: #120124;
  border: 1px solid #7120c8;
  border-radius: 2px;
  padding: 10px 41px;

  font-weight: 300;
  font-size: 12px;
  line-height: 18px;

  color: #fefdff;
}

.Leaderboard-title-search::placeholder {
  color: #ebe2dd;
}

.Leaderboard-title-search-image {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 14px;
  cursor: pointer;
}

.Leaderboard-title-clear-image {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 14px;
  cursor: pointer;
}

.Leaderboard-table-wrapper {
}

.Leaderboard-table-large {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.Leaderboard-table-position-image {
  width: 50px;
  height: 50px;
}

.Leaderboard-table-header {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #6b7c86;
}

.Leaderboard-table-row {
  background: #1e0839;
  border: 1px solid #7120c8;
  height: 60px;
}

.Leaderboard-table-winSeparator {
  color: #6b7c86;
  margin: 0 2px;
  font-weight: 500;
}

.Leaderboard-table-wallet-image {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 20px;
  top: 15px;
}

.Leaderboard-table-arrow-image {
  width: 24px;
  height: 24px;
}

.Leaderboard-table-th {
  cursor: pointer;
}

.Leaderboard-table-large td {
  border-top: 1px solid #7120c8;
  border-bottom: 1px solid #7120c8;
  text-align: center;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.Leaderboard-table-large th:nth-child(2) {
  text-align: left;
  padding-left: 18px;
}

.Leaderboard-table-large th:nth-child(7) {
  width: 105px;
}

.Leaderboard-table-large td:nth-child(2) {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  padding-left: 59px;
}

.Leaderboard-table-large td:first-child {
  border-left: 1px solid #7120c8;
  width: 70px;
  background: #120124;
}
.Leaderboard-table-large td:last-child {
  border-right: 1px solid #7120c8;
  width: 60px;
  padding-right: 25px;
}

.Leaderboard-table-header-item-wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.Leaderboard-table-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.Leaderboard-table-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  cursor: pointer;
  width: 12px;
  height: 7px;
}

.Leaderboard-paginate-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Leaderboard-pagination {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.Leaderboard-page-item {
  /* color: #fbd365;
  background-color: inherit; */
}

.Leaderboard-page-item .active {
  /* color: #fbd365;
  background-color: inherit; */
  color: #fefdff;
}

.Leaderboard-page-item.active .Leaderboard-page-link {
  /* z-index: 3; */
  /* color: #fbd365; */
  /* background-color: #7120c8; */
  /* border-color: #7120c8; */
}

/* .page-item.disabled .page-link {
  background-color: #7120c87a;
  border-color: #7120c8;
} */

.Leaderboard-page-link {
  cursor: pointer;
  padding: 5px 7px;
  position: relative;
  display: block;
  color: #fbd365;
  text-decoration: none;
  background-color: inherit;
  /* border: 1px solid #7120c8; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.Leaderboard-page-link:focus {
  z-index: 3;
  color: #fefdff;
  /* background-color: #7120c8; */
  outline: 0;
  /* border-color: #7120c8; */
  /* box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%); */
}

.Leaderboard-page-link:hover {
  opacity: 0.7;
  /* background-color: #7120c8; */
  /* color: #fbd365; */
  /* border-color: #fbd365; */
}

.Leaderboard-page-link .active {
  color: #fefdff;
  background-color: inherit;
  /* border: 1px solid #7120c8; */
}

.Leaderboard-cards-wrapper {
  display: none;
}

.Leaderboard-card {
  width: 100%;
  padding: 20px 10px 20px 20px;
  border: 1px solid #7120c8;
}

.Leaderboard-card-row-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Leaderboard-card-row-wrapper-padding-right {
  padding-right: 10px;
}

.Leaderboard-card-row-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: #6b7c86;
}

.Leaderboard-card-row-value {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #fefdff;
}

.Leaderboard-card-row-value-wallet {
  color: #fbd365;
}

@media (max-width: 1000px) {
  .Leaderboard-title-block-wrapper {
    flex-direction: column;
    gap: 15px;
  }

  .Leaderboard-title-search-wrapper {
    width: 100%;
    min-width: auto;
  }

  .Leaderboard-table-wrapper {
    display: none;
  }

  .Leaderboard-cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 100%;
  }

  .Leaderboard-sort-dropdown-wrapper {
    display: block;
  }
}

@media (max-width: 550px) {
  .Leaderboard-title-search-block-wrapper {
    flex-direction: column;
  }

  .Leaderboard-button-outline {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .Leaderboard-title {
    font-size: 32px;
  }
}
