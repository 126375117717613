.ListboxWrapper {

}

.Listbox {

}
.ListboxButton {
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
  /* font-size: 14px;
  line-height: 21px; */
  color: #fefdff;

  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-between;

  padding: 4px 10px;
  width: 178px;
  height: 23px;
  /* height: 29px; */
  border: 1px solid #581DAD;
  border-radius: 6px;
  background-color: inherit;

}

.ListboxOptions {
  opacity: 1;
}

.ListboxArrowImage {
  width: 20px;
  height: 20px;
}


.menu-items:focus-visible {
  border: 1px solid #262638;
}

.menuItems {
  position: absolute;
  right: 25px;
  top: 7.3rem;
  min-width: 15.5rem;
  width: 178px;
  transform-origin: top right;
  border-radius: 0.4rem;
  background: #1E0839;
  border: 1px solid #581dad;
  list-style: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
}
.menuItem {
  display: flex;
  align-items: center;
  font-size: var(--font-base);
  color: #6b7c86;
  padding-bottom: 1.5rem;
  font-size: var(--font-sm);
  padding: 0.85rem 0.8rem;
  border-radius: 0.4rem;
}
.menuItem:hover {
  background: #808aff14;
  border-radius: 0.4rem;
  opacity: 1;
  color: #fefdff;
}
.menuItem > p {
  margin: 0px;
  padding-left: 1rem;
}
.menuItem > a {
  display: inline-flex;
}

@media screen and (max-width: 370px) {
  .user-address {
    margin-left: 0;
  }
  .address-btn {
    display: flex;
    justify-content: space-between;
  }
  .user-avatar {
    display: none;
  }
}