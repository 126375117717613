.Title {
  font-size: 24px;
  padding: 0;
}

.Subtitle-1 {
  font-size: medium;
}

.Page-container {
  margin-left: 2%;
  margin-right: 2%;
}

.Medium-link {
  font-size: medium;
  margin-top: 2%;
  /* margin-bottom: 2%; */
}

.Subtitle-2 {
  font-size: large;
  margin-top: 10%;
}

.Stats-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 4%;
  margin-top: 0px;
}

.Stats-item {
  /* text-align: center;
  align-items: center;
  justify-content: center; */
  /* border-style: dashed;
  border-width: 1px; */
  /* width: auto; */
  /* padding: auto; */
  flex: 1;
}

.Stats-label {
  font-size: 18px;
}

.Stats-value {
  font-size: 28px;
}

/* Make vertical, add margins for vertical layout */
@media (max-width: 600px) {
  .Stats-container {
    flex-direction: column;
    margin-top: 6%;
  }

  .Stats-item,
  .Stats-item-profit,
  .stats-item-loss {
    margin-bottom: 10px;
  }

  .Stats-label {
    font-size: 18px;
  }

  .Stats-value {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) {
  .Title {
    font-size: 64px;
    padding: 0;
  }
  .Subtitle-1 {
    font-size: x-large;
  }

  .Medium-link {
    font-size: large;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  .Subtitle-2 {
    font-size: large;
    margin-top: 5%;
  }
}

.Enable-mainnet-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Enable-mainnet-button {
  margin-top: 10%;
}

li {
  margin: 10px 4px;
}

.Telegram-linked-container {
  display: flex;
  flex-direction: column;
}

.Telegram-linked-text-container {
  display: flex;
  align-items: center;
}

.Telegram-linked-text {
  font-size: x-large;
  padding-left: 5px;
}

.Telegram-verified-icon {
  width: 40px;
}

/* -------------------------- */
.TelegramPage__wrapper {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: #270a45;
}

.TelegramPage {
  width: 100%;
  padding-bottom: 100px;
}

.TelegramPage::-webkit-scrollbar {
  width: 5px;
}
.TelegramPage::-webkit-scrollbar-track {
  background: transparent;
}
.TelegramPage::-webkit-scrollbar-thumb {
  background-color: #721ecb;
  border-radius: 15px;
  border: 1px solid #721ecb;
}

.topBlock__wrapper {
  width: 100%;
  height: 650px;
  padding: 0 116px 0 158px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  background: linear-gradient(180deg, #0d0417 0%, #1e0839 100%);
}

.topBlock__left {
  width: 480px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.topBlock__left_title {
  color: #fff;
  text-align: left;
  font-family: Outfit;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.topBlock__left_subtitle {
  width: 468px;
  color: #fff;
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.topBlock__left_subtitle > span {
  color: #dfc437;
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.stats__wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stats__title {
  color: #d9d9d9;
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.stats__value {
  color: #d9d9d9;
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button__wrapper {
  width: 300px;
}

.step {
  margin-top: 8%;
  margin-bottom: 2%;
  font-family: Outfit;
  font-size: 22px;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
}

.topBlock__right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topBlock__right_image {
  width: 565px;
  height: 565px;
}

.bottomBlock__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  background: #270a45;

  padding-top: 30px;

  width: 100%;
}

.bottomBlock__title {
  color: #fff;
  text-align: center;
  font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 41px;
}

.cards__row {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  /* justify-content: space-between; */
}

@media (max-width: 1330px) {
  .topBlock__wrapper {
    padding: 0 30px 0 72px;
  }
}

@media (max-width: 1150px) {
  .topBlock__wrapper {
    height: 435px;
  }

  .topBlock__left {
    width: 370px;
  }

  .topBlock__left_title,
  .stats__title,
  .stats__value {
    font-size: 32px;
  }

  .topBlock__left_subtitle,
  .topBlock__left_subtitle > span {
    font-size: 18px;
    width: 300px;
  }

  .topBlock__right_image {
    width: 350px;
    height: 350px;
  }
  .cards__row {
    flex-direction: column;
    align-items: center;
  }

  .cards {
    gap: 15px;
  }
}

@media (max-width: 850px) {
  .topBlock__wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;

    height: auto;

    padding: 72px 30px 30px 30px;
  }

  .topBlock__left {
    align-items: center;
  }

  .topBlock__left_subtitle,
  .stats__title {
    text-align: center;
  }

  .button__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .topBlock__left_title,
  .stats__title,
  .stats__value {
    font-size: 22px;
  }

  .topBlock__left_subtitle,
  .topBlock__left_subtitle > span {
    font-size: 12px;
    width: 200px;
  }
}
