.Token-card {
  min-height: 240px;
  position: relative;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 34px;

  max-width: 59.2rem;
  border: 1px solid #581dad;
  border-radius: 20px;

  background: #120124;
}

.Token-card-option-icon {
  height: 65px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: #fefdff;
  border-bottom: 1px solid #ebe2dd;
}

.Token-card-option-icon-image {
  width: 40px;
  height: 40px;
  margin-right: 0.8rem;
}

.Token-card-button-section {
  display: flex;
  gap: 16px;
}
.Token-card-button-solid {
  padding: 8px 32px;
  background: #7120c8;
  border-radius: 33px;
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #fefdff;
}

.Token-card-button-outline {
  padding: 8px 32px;
  background: inherit;
  border: 1px solid #7120c8;
  border-radius: 33px;
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #fefdff;
}

.Token-card-option-apr {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 1.9375rem;
}

.Token-card-apr {
  margin-left: auto;
  margin-right: 0;
  font-size: 20px;
}

.Token-card-apr-label {
  display: inline;
  color: #aaa5ad;
}

@media (max-width: 550px) {
  .Token-card-button-section {
    flex-direction: column;
  }
}
