.BuyGMX {
  /* background: #101124; */
  /* justify-content: space-between; */
}

.BuyGMX-container {
  padding-top: 4.65rem;
  padding-bottom: 3.1rem;
  width: 100%;
}

.BuyGMX-description {
  letter-spacing: 0px;
  color: #a9a9b0;
}

.BuyGMX-section-title-block {
  margin-bottom: 43px;
}

.BuyGMX-section-title-content {

}

.BuyGMX-Page-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -2px;
  color: #fff;
}

.BuyGMX-Page-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #fefdff;
}

.BuyGMX-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.BuyGMX-card-wrapper {

}

.BuyGMX-card-button {
  width: 255px;
  height: 80px;
}

.BuyGMX-page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  padding-bottom: calc(4.65rem + 5rem);
  padding-top: 1.65rem;
}

/* -------------------------------- */ 


.bridge-options {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 1.5rem;
}
.buy-gmx {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  margin: 3.1rem 0;
}
.alternative-bridges .transfer-eth-icon {
  width: 5.58rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.direct-purchase-options {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin: 3rem 0;
  justify-content: center;
}

.GMX-btn {
  display: flex;
  align-items: center;
  background: #ffffff0a;
  border: 1px solid #ffffff14;
  border-radius: 0.4rem;
  text-decoration: none;
  height: 4.65rem;
  padding: 0.8rem 1.5rem;
  margin: 3.1rem 0;
}

.GMX-btn:hover {
  background: #ffffff14;
}

.GMX-btn:focus,
.GMX-btn:active {
  background: #ffffff1f;
}

.GMX-btn-icon-block {
  display: flex;
  margin-right: 1.165rem;
}

.GMX-btn-icon {
  display: flex;
  position: relative;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
}

.GMX-btn-icon-network {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
}

.GMX-btn-label {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 1.85rem;
  line-height: 2.325rem;
}

.GMX-block-section {
  display: flex;
  justify-content: center;
}

.display-bg {
  display: block;
}

.display-sm {
  display: none !important;
}

.card-title {
  font-size: 3.41rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.cards-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-bottom: 2rem;
}
.card-description {
  opacity: 0.7;
  margin: 0;
}

.buttons-group {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin-top: 1rem;
}

.col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.col-1 {
  display: inline-flex;
}

.mt-top {
  margin-top: 5rem;
}
.exchange-info-group:not(:last-child) {
  margin-bottom: 1.5rem;
}

@media (max-width: 1200px) {
  .buttons-group {
    grid-template-columns: repeat(2, 1fr);
  }

  .BuyGMX-Page-title {
    font-size: 29px;
    line-height: 43px;
  }
  
  .BuyGMX-Page-description {
    font-size: 14px;
    line-height: 22px;
  }

  .BuyGMX-section-title-block {
    margin-bottom: 34px;
  }
}

@media (max-width: 800px) {
  .display-bg {
    display: none !important;
  }
  .display-sm {
    display: block !important;
  }
  .card-details {
    display: flex;
    flex-direction: column;
  }
  .section-title-block {
    margin-bottom: 1.5rem !important;
  }
  .cards-row {
    grid-template-columns: 1fr;
  }

  .BuyGMX-Page-title {
    font-size: 22px;
    line-height: 32px;
    text-align: center;
  }
  
  .BuyGMX-Page-description {
    font-size: 11px;
    line-height: 16px;
    text-align: center;
  }

  .BuyGMX-section-title-block {
    margin-bottom: 26px;
  }
}

@media (max-width: 400px) {
  .BuyGMXGLP-description {
    font-size: var(--font-base);
    line-height: 2rem;
  }
  .buttons-group {
    grid-template-columns: auto;
  }
  .col-1 {
    display: grid;
  }

  .BuyGMX-Page-title {
    font-size: 18px;
    line-height: 27px;
  }
  
  .BuyGMX-Page-description {
    font-size: 9px;
    line-height: 14px;
  }

  .BuyGMX-section-title-block {
    margin-bottom: 22px;
  }
}

@media (max-width: 370px) {
  .cards-row {
    grid-template-columns: auto;
  }
}
