.ValidationRow {
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: var(--font-base);
}

.ValidationRow-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ValidationRow-icon {
  margin-right: 1.5rem;
}

.BeginAccountTransfer .ValidationRow {
  margin-bottom: 1.5rem;
  font-size: var(--font-base);
}

.BeginAccountTransfer-validations {
  margin-bottom: 2.325rem;
}

.BeginAccountTransfer .Modal .App-cta {
  display: block;
  text-align: center;
  font-size: var(--font-base);
}

.BeginAccountTransfer-page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  padding-bottom: calc(4.65rem);
  padding-top: 4.65rem;
}