.Invite-text {
  font-size: x-large;
}

.Enable-mainnet-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Enable-mainnet-button {
  margin-top: 10%;
}