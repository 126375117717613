.card {
  &__wrapper {
    width: 328px;
    height: 191px;
    flex-shrink: 0;

    background-color: #271e1e;
    box-shadow: (0px 8px 4px rgba(0, 0, 0, 0.5));

    border-radius: 8px;

    padding: 15px 30px 0 30px;
  }

  &__title {
    color: #dfc437;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__text {
    color: #fff;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
