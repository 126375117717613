.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: 1.1rem;
  /* font-size: var(--font-sm); */
  overflow: hidden;
  color: #6b7c86;
  background: #120124;
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab-option-icon {
  margin-right: 0.465rem;
  opacity: 0.7;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
}

.Tab.block .Tab-option:hover {
  color: rgba(255, 255, 255, 1);
  background: #7120c8dc;
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: #391065;
  color: #fefdff;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-base);
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}
