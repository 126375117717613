@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.link-hover {
  text-decoration: underline;
  cursor: pointer;
  display: inline-flex;
  color: rgba(255, 255, 255, 0.7);
  &:hover {
    color: rgba(255, 255, 255);
  }
}

a.section{
  &__link{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #FBD365;
    text-decoration: none;
    &:hover{
      text-decoration: none;
      color: #FBD365;
    }
    img{
      margin-left: 6px;
    }
  }
}
