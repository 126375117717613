.BuyGMXGLP {
  justify-content: space-between;
}

.Buy-page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  padding-bottom: calc(2.65rem + 10rem);
  padding-top: 4.65rem;
}

.BuyGMXGLP-container {
  padding: 0 0 3.1rem 0;
  width: 100%;
}

.BuyGMXGLP-container .section-title-content {
  justify-content: flex-start;
}
